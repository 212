$xs: 575.98px;
$sm: 767.98px;
$md: 991.98px;
$lg: 1199.98px;
$xl: 1449.98px;
$xxl: 1919.98px;


.v-home {
  display: block;
  width: 100%;

  h2 {
    font-size: 30px;
    margin-bottom: 50px;
  }

  p {
    font-size: 18px;
    line-height: 1.9 !important;
    color: #747474;
  }

  .o-text-darkMode p {
    color: white;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start
  }

  &__hero {
    position: relative;
    display: block;
    padding-top: 120px;
    color: white;
    background-color: $blackAlt;
    margin-bottom: -10vw;

    &--parallax {
      position: relative;
    }

    &--content {
      position: relative;
      font-size: 40px;
      z-index: 2;

      .slider {
        &--item {
          h2 {
            display: block;
            text-align: center;
            font-weight: 300;
            font-size: 60px;
            color: white;
            text-shadow: 0px 20px 50px #002331;

            @media(max-width: 767.98px) {
              font-size: 30px
            }

            span {
              display: block;
              margin-top: 10px;
              font-family: Inknut Antiqua, serif;
              font-size: 40px;
              font-weight: 300;
              color: $primary;
            }
          }
        }
      }
    }

    &--fromage {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      margin-top: -30px;

      z-index: 5;

      @media(max-width: $md) {
        margin-top: 0;
        margin-bottom: -120px;
      }

      img {
        max-width: calc(50% + 15vw);
        margin-right: -15vw;

        @media(max-width: $md) {
          max-width: 100%;
          margin-right: 0;
          margin-top: 30px;
        }
      }
    }
  }

  &__degustation {
    position: relative;
    display: block;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      top: 8vw;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $blackAlt;
      z-index: -4;
    }

    .patch {
      position: absolute;
      top: 150px;
      display: block;
      width: 100%;
      max-width: 500px;
      z-index: 0;

      @media(max-width: $sm) {
        max-width: 250px;
        top: 100px;
        right: calc(50% - 125px);
      }
    }

    &--content {
      position: relative;
      display: flex;
      align-content: stretch;
      align-items: center;
      gap: 40px;
      padding: 300px 0 150px 0;
      margin-right: -20px;
      margin-left: -20px;

      font-size: 30px;

      color: $primary;
      z-index: 2;

      @media(max-width: $lg) {
        flex-wrap: wrap;
      }

      h2 {
        margin: 0;
      }

      .content {
        display: block;
        width: 100%;
        max-width: 60%;
        padding: 0 20px;

        @media(max-width: $lg) {
          max-width: 100%;
        }

        @media(max-width: $sm) {
          text-align: center;
        }
      }

      .buttons {
        display: flex;
        gap: 40px;
        width: 100%;
        max-width: 40%;
        height: 100%;
        padding: 0 20px;
        align-items: flex-start;
        justify-content: flex-end;

        @media(max-width: $lg) {
          max-width: 100%;
          justify-content: flex-start;
        }

        @media(max-width: $sm) {
          justify-content: center;
        }

        @media(max-width: 400px) {
          display: block;

          .button-yellow {
            display: block;
            text-align: center;

            &:first-child {
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    &--background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: -1;

      .gradiant {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 75%;
        background: linear-gradient(180deg, rgba($blackAlt, 0) 0%, rgba($blackAlt, 1) 100%);
        z-index: 3;
      }

      img {
        position: relative;
        width: 100%;
        height: auto;
        z-index: 2;
      }
    }

    &--fromages {
      display: block;

      .slick-slide {
        transition: 1s;
      }

      .slick-list {
        //overflow: visible;
      }

      .slick-current {
        margin-top: -60px;
      }

      .item {
        display: block;
        padding: 0 20px;

        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 80px;
        }
      }
    }
  }

  &__produits {

    @media(max-width: $xxl) {
      padding: 0;

      .padding-content {
        padding: 0;
      }
    }

    &--row {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start
    }

    &--image,
    &--content {
      display: block;
      width: 100%;
      max-width: 50%;

      @media(max-width: $lg) {
        max-width: 100%;
      }
    }

    &--image {
      position: relative;

      .content {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: 100%;
        padding: 60px;
        justify-content: flex-end;
        gap: 60px;
        z-index: 2;

        @media(max-width: $lg) {
          padding: 15px;
          gap: 15px;
          justify-content: center;
        }

        &--title {
          margin-top: 30px;
          font-size: 40px;
          font-family: Inknut Antiqua, serif;
          font-weight: 300;

          @media(max-width: $lg) {
            margin-top: 0;
            font-size: 20px;
          }
        }

        img {
          width: 100%;
          max-width: 140px;

          @media(max-width: $lg) {
            max-width: 100px;
          }
        }
      }

      .image {
        position: relative;
        background-color: pink;
        width: 50vw;
        margin-left: calc(-50vw - 12.2vw + 50vw);
        padding-top: 106%;

        @media(max-width: $xxl) {
          width: 100%;
          margin-left: 0;
        }

        @media(max-width: $lg) {
          padding-top: 50%;
        }
      }
    }

    &--content {
      position: relative;
      padding: 0 100px 100px;

      @media(max-width: $lg) {
        padding: 60px 20px;
      }

      .content--subTitle {
        display: block;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2px;

        text-transform: uppercase;
        color: #B3B3B3;
      }

      .texte {
        padding-right: 100px;

        @media(max-width: $sm) {
          padding-right: 0;
        }
      }

      .tag {
        position: absolute;
        bottom: 40px;
        right: -40px;
        display: block;
        width: 100%;
        max-width: 200px;

        @media(max-width: $sm) {
          right: 15px;
          bottom: -100px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 50vw;
        background-color: $blackAlt;
        z-index: -1;

        @media(max-width: $xl) {
          width: 100vw;
        }
      }
    }
  }

  &__aboutUs {
    position: relative;
    display: block;
    padding: 100px 0;
    overflow-x: hidden;

    .v-home__row {
      position: relative;
      margin: 0 -80px;
    }

    &--content,
    &--images {
      display: block;
      width: 100%;
      max-width: 50%;
      padding: 0 80px;

      @media(max-width: $md) {
        max-width: 100%
      }
    }

    &--content {
      padding-top: 120px;

      @media(max-width: $md) {
        padding-top: 0;
      }

      h2 {
        font-size: 30px;
      }

      .texte {
        padding-right: 30px;
        margin-bottom: 80px;
      }

      @media(max-width: $md) {
        .button-yellow {
          display: inline-block;
          margin-bottom: 60px;
        }
      }
    }

    &--images {

      .image {

        &--small,
        &--long {
          display: block;
        }

        &--small {
          width: calc(100% - 7vw);
          margin-left: auto;

          @media(max-width: $md) {
            width: 60vw;
          }
        }

        &--long {
          width: calc(50% + 5vw);
          margin-top: -160px;

          @media(max-width: $md) {
            margin-top: -80px;
            width: 50vw;
          }
        }
      }
    }
  }

  &__savoirFaire {
    display: block;
    width: 100%;
    overflow-x: hidden;

    .v-home__row {
      align-items: center;
      margin: 0;
    }

    &--content,
    &--image {
      display: block;
      width: 100%;
      max-width: 50%;

      @media(max-width: $lg) {
        max-width: 100%;
      }
    }

    &--image {

      img {
        position: relative;
        width: 100%;
        max-width: 200px;
        height: auto;
        margin-bottom: -60px;
        margin-left: -100px;
        z-index: 2;

        @media(max-width: $xxl) {
          margin-left: 0;
        }

        @media(max-width: $md) {
          max-width: 150px;
        }
      }

      .image {
        position: relative;
        width: 50vw;
        margin-left: calc(-50vw - 12.2vw + 50vw);
        height: 100%;
        padding-top: 80%;

        @media(max-width: $lg) {
          display: block;
          text-align: center !important;
          width: 100%;
          padding-top: 52.26%;
          margin: 0;
        }
      }
    }

    &--content {
      padding-left: 100px;

      @media(max-width: $md) {
        padding: 0 !important;
      }

      .button-yellow {
        display: inline-block;
        margin-top: 60px;
      }
    }
  }

  &__blogue {
    position: relative;
    display: block;
    padding: 150px 0;
    width: 100vw;
    overflow-x: hidden;

    @media(max-width: $sm) {
      padding: 100px 0;
    }

    h2 {
      color: #6A5A54;
    }

    .v-home__row {
      flex-wrap: wrap;
      margin: 0 -15px;
      justify-content: flex-start;

      @media(max-width: $sm) {
        flex-direction: column-reverse;
      }
    }

    &--header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 80px;

      h2 {
        margin: 0 60px 0 0;
      }

      @media(max-width: $md) {
        display: block;
        h2 {
          display: block;
          margin-bottom: 30px;
        }
      }
    }

    &--content,
    &--image {
      position: relative;
      display: block;
      width: 100%;
      max-width: 50%;
      padding: 0 15px;

      @media(max-width: $sm) {
        max-width: 100%;
      }
    }

    &--content {
      .post--cat {
        display: block;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 2px;

        text-transform: uppercase;
        color: #efb55a;
      }

      .title {
        font-size: 30px;
      }

      .post--footer {
        display: block;
        width: 100%;

        .post--socials,
        .post--button {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          max-width: 50%;

          @media(max-width: $sm) {
            max-width: 100%;
          }
        }

        .post--button {
          .btn {
            font-size: 18px;
            margin: 0;
            border: 0;

            @media(max-width: $sm) {
              display: block;
              padding: 10px 15px;
              border: 2px solid $primary;
            }
          }
        }

        .post--socials {
          display: flex;
          gap: 15px;
          margin-bottom: 30px;

          a {
            display: flex;
            width: 30px;
            height: 30px;
            justify-content: center;
            align-content: center;

            svg {
              height: 20px;
              fill: $gray;
            }

            &:hover {
              svg {
                fill: $primary
              }
            }
          }
        }

        .post--button {
          @media(max-width: $sm) {
            justify-content: flex-start;
            margin-bottom: 60px;
          }
        }
      }
    }

    &--image {

      .image {
        position: relative;
        width: 100%;
        padding-top: 69%;
      }

      @media(max-width: $sm) {
        margin-bottom: 30px;
      }
    }

    &--icon {
      position: absolute;
      top: 200px;
      right: -90px;

      img {
        width: 500px;
      }

    }
  }

  &__otherProducts {
    position: relative;
    padding: 140px 0 200px;
    background-color: #333;

    &--marquee {
      display: block;
      width: 100%;
      font-family: Inknut Antiqua, serif;
      font-size: 80px;
      color: #484240;
      margin-bottom: -20px;

      &.top {
        margin-bottom: 0;
      }

      .text {
        position: relative;
        float: left;
        width: auto !important;
        padding: 0 50px;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -10px;
          display: block;
          width: 20px;
          height: 20px;
          background-color: #484240;
          border-radius: 50%;
        }
      }

      .slick-slider .slick-slide {
        display: inline-block;
        width: auto !important;
      }
    }

    &--row {
      display: flex;
      flex-wrap: wrap;
    }

    &--title {
      display: block;
      width: 100%;
      max-width: 20%;

      .button-yellow {
        display: inline-block;
      }

      @media(max-width: $lg) {
        max-width: 100%;
        margin-bottom: 60px;
      }

      h2 {
        font-size: 40px !important;
        color: #efb55a !important;
      }

      .bouclier {
        width: 100%;
        max-width: 480px;
        top: 55%;
        margin-left: 70px;
        @media(max-width: $lg) {
          display: none;
        }
      }
    }

    &--images {
      display: block;
      width: 100%;
      max-width: 80%;

      @media(max-width: $lg) {
        max-width: 100%;
      }

      .mosaic {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &--col-1 {
          display: block;
          width: 100%;
          max-width: percentage(4/12);

          @media(max-width: $lg) {
            max-width: 100%;
          }

          .mosaic--item {
            padding-top: 167.5%;

            @media(max-width: $sm) {
              padding-top: 100%;
            }
          }
        }

        &--col-2 {
          width: 100%;
          max-width: percentage(8/12);
          padding: 0 5px;

          @media(max-width: $sm) {
            max-width: 100%;
            padding: 0;
          }

          .item-col {
            &-1,
            &-2 {
              width: 100%;
              padding: 0 5px;

              @media(max-width: $sm) {
                padding: 0;
              }

              .mosaic--item {
                padding-top: 100%;
              }
            }

            &-1 {
              max-width: 50%;
              @media(max-width: $sm) {
                max-width: 100%;
                margin-top: 10px;
              }
            }

            &-2 {
              max-width: 100%;
              margin-top: 10px;

              .mosaic--item {
                padding-top: 35.204359%;
                @media(max-width: $sm) {
                  padding-top: 100%;
                }
              }
            }
          }
        }

        &--item {
          position: relative;
          display: block;
          width: 100%;
        }
      }
    }
  }


  /*
  &__other {
    position: relative;
    padding-top: 140px;
    padding-bottom: 140px;
    background-color: #333;

    .other {
      padding-top: 0;
      background-color: transparent;

      &-title {
        top: 0;
        display: inline-flex;
        flex-wrap: wrap;

        p {
          display: block;
          padding-left: 30px;
          text-align: left;
          line-height: 1.5 !important;
          margin-bottom: 30px
        }

        .button-yellow {
          margin-left: calc(25% + 30px);
        }
      }
    }

    .bouclier {
      top: 50%;
    }

    &--marquee {
      display: block;
      width: 100%;
      font-family: Inknut Antiqua, serif;
      font-size: 80px;
      color: #484240;
      margin-bottom: -20px;

      &.top {
        margin-bottom: 0;
      }

      .text {
        position: relative;
        float: left;
        width: auto !important;
        padding: 0 50px;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -10px;
          display: block;
          width: 20px;
          height: 20px;
          background-color: #484240;
          border-radius: 50%;
        }
      }

      .slick-slider .slick-slide {
        display: inline-block;
        width: auto !important;
      }
    }

    .back-button {
      padding: 30px;
      height: auto;
    }

    .button-yellow {
      display: inline-block;
    }

    @media(max-width: $lg) {

      .gray-line {
        display: none;
      }
      .other-title {
        width: 100%;

        p {
          display: block;
          margin: 0;
          line-height: 1.3 !important;
        }
      }

      .flexmosaic {
        margin-bottom: 30px;
      }

      .mosaic {
        padding: 30px;
      }

      .bouclier {
        display: none;
      }
    }
  }
  */

  &__goTo {
    position: relative;
    padding-top: 170px;

    background-size: cover;
    background-position: top center;
    z-index: 2;

    &--phrases {
      position: relative;
      display: block;
      max-width: 1200px;
      z-index: 2;

      @media(max-width: $md) {
        margin-bottom: 36px;
      }

      div {
        display: block;
        font-family: Inknut Antiqua, serif;
        font-size: 80px;
        color: rgba(white, .6);

        &:first-child {
          font-size: 40px;
          color: white;
        }

        @media(max-width: $md) {
          font-size: 30px;
          text-align: center;
          &:first-child {
            font-size: 20px;
          }
        }
      }
    }

    &--visiteGuidees {
      position: relative;
      left: -240px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 420px;
      height: 420px;

      margin-top: -50px;
      margin-left: auto;

      border-radius: 50%;
      color: white;
      background-color: rgba($primary, .69);

      @media(max-width: $md) {
        left: 0;
      }

      h3 {
        display: block;
        font-size: 40px;
        text-align: center;
        color: white;
        margin: 0;
      }

      p {
        display: block;
        font-size: 18px;
        color: white;
        margin: 30px 0;
      }

      a {
        display: inline-block;
        padding: 15px 30px;
        margin: 0;
        font-size: 16px;
        color: white;
        border: 2px solid white;

        &:hover {
          color: $primary;
          background-color: white;
        }
      }

      @media(max-width: $md) {
        text-align: center;
        left: 0;
        width: 250px;
        height: 250px;
        font-size: 20px;

        transform: translateY(0);
        margin: 0 auto 60px;

        p {
          font-size: 14px;
          margin: 15px 0;
        }
      }
    }

    &--ctaArret {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 420px;
      height: 420px;
      margin-top: -90px;
      border-radius: 50%;
      transform: translateY(90px);
      font-family: Inknut Antiqua, serif;
      font-size: 40px;
      text-align: center;

      color: $primary;
      background-color: rgba(#6A5A54, .49);

      border: 10px solid $primary;

      @media(max-width: $md) {
        width: 250px;
        height: 250px;
        font-size: 30px;

        transform: translateY(0);
        margin: 0 auto 60px;

        h3 {

        }
      }
    }

    @media(max-width: $md) {
      padding-bottom: 60px;
    }
  }

  .fixOverflow {
    display: block;
    width: 100vw;
    overflow-x: hidden;
  }
}
