@charset "UTF-8";
/*
	COMPONENTS
		Wordpress
*/
/* Images */
img.alignnone,
.wp-caption.alignnone,
img.alignleft,
.wp-caption.alignleft {
  float: left;
  margin: 0 1.1em 1.1em 0;
}

img.alignright,
.wp-caption.alignright {
  float: right;
  margin: 0 0 1.1em 1.1em;
}

img.aligncenter,
.wp-caption.aligncenter {
  display: block;
  margin: 0 auto 1.1em;
}

.wp-caption-text {
  font-size: 0.75em;
  margin: 1.45em 0;
}

[id^=attachment_] {
  width: auto !important;
}

.padding-content {
  padding: 0 12.2%;
}

.home .main-holder {
  background-color: #f3f3f3;
  padding: 120px 0;
}

.home .main-holder .btn {
  margin-top: -40px;
}

/*----Texte et photo------*/
.pad-text,
.pad-text-right {
  padding: 40px;
}

.pad-text h2,
.pad-text-right h2 {
  margin-bottom: 60px;
}

.flex-container {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.col-mid {
  flex: 1;
}

.flex-2 {
  flex: 0 0 50%;
}

.flex-3 {
  flex: 0 0 33.33%;
}

.flex-4 {
  flex: 0 0 25%;
}

.mobile-appear {
  display: none;
}

.border {
  width: 90px;
  height: 2px;
  background-color: #EFB55A;
  margin: 0 auto 30px;
  display: block;
}

/*----Texte------*/
.padding {
  padding: 20px 0;
}

/*----Appel a l'action------*/
.appel {
  position: relative;
  min-height: 450px;
  width: 100%;
  text-align: center;
  padding: 80px;
}

.appel h2 {
  color: #ffffff;
}

.back-appel {
  position: absolute;
  height: 100%;
  width: 100%;
  background-attachment: fixed !important;
  z-index: -2;
  top: 0;
  left: 0;
}

.mask-grey {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
  top: 0;
  left: 0;
}

.max-width-text {
  max-width: 50%;
  margin: 0 auto 40px;
  display: block;
}

.max-width-text p {
  color: #ffffff;
}

.submit {
  background-color: #EFB55A !important;
  padding: 10px 25px;
  color: #ffffff !important;
  border-radius: 0 !important;
}

.btn {
  color: #808080;
  margin: 10px 25px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.no-touchevents .btn:hover {
  border-bottom: 2px solid #EFB55A;
  color: #EFB55A;
}

.no-touchevents .submit:hover {
  background-color: #333333 !important;
  color: #ffffff !important;
}

/*----Équipe------*/
.membre-container {
  padding: 80px;
}

.membre {
  text-align: center;
  padding: 0 10px;
}

.membre img {
  max-height: 100%;
}

.service-container .membre img {
  max-height: 125px;
}

.name-membre {
  margin-top: 30px;
  text-transform: uppercase;
  color: #000;
}

.uppercase {
  text-transform: uppercase;
}

.social-person a {
  display: inline-block;
}

/*----Carrousel------*/
.carrousel,
.swiper-carrousel {
  width: 100%;
  height: 300px;
}

/*----témoignages------*/
.person {
  max-height: 300px;
  margin-bottom: 20px;
}

.tem {
  padding: 20px 25%;
}

.tem img {
  display: inline-block;
}

.temoignage {
  display: inline-block;
  margin-left: 40px;
  vertical-align: top;
}

.temoignage p {
  margin: 0;
}

.tem i {
  color: #333333;
  float: right;
}

/*----Services------*/
.service-container {
  position: relative;
  padding: 80px;
}

.service-container h2 {
  text-align: center;
}

.service-container p {
  color: black;
  font-size: 16px;
}

.name-membre {
  font-size: 18px;
}

.back-splash-service {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background-attachment: fixed !important;
}

.service-container .mask {
  z-index: -1;
}

/*----FAQ------*/
.faq-container {
  padding: 120px 20%;
  position: relative;
}

.faq {
  margin-top: 40px;
}

.q {
  background-color: #EFB55A;
  padding: 10px 20px;
  color: white;
  list-style: none;
  cursor: pointer;
  position: relative;
}

.a {
  background-color: #333333;
  padding: 10px 20px;
  color: white;
  list-style: none;
  display: none;
}

.arrow {
  height: 20px;
  width: 25px;
  position: absolute;
  right: 10px;
  transition: 1s;
}

.arrow.rotate {
  transform: rotate(180deg);
}

.interrogation {
  position: absolute;
  bottom: -50px;
  right: -50px;
  transform: scale(0.7);
  opacity: 0.3;
  z-index: -1;
}

/*----Réalisation------*/
.realisation-container {
  padding: 80px;
}

.real {
  text-align: center;
  padding: 20px;
}

.swiper-slide .text-realisation {
  padding: 40px 40px 0;
  opacity: 0;
  transition: 1s;
}

.swiper-slide-active .text-realisation {
  opacity: 1;
}

.title-realisation {
  text-transform: uppercase;
  color: #EFB55A;
}

/*----Contact------*/
.contact {
  padding: 80px 12%;
}

.col-6 {
  display: inline-block;
  width: 49%;
}

input {
  width: 98%;
  height: 40px;
  padding-left: 10px;
  background: none;
  color: #ffffff;
  border: 1px solid #EFB55A;
}

select {
  height: 40px;
  padding-left: 10px;
  background: none;
  color: #ffffff;
  border: 1px solid #EFB55A;
}

.qty {
  height: 60px;
}

option {
  color: black;
}

textarea {
  width: 98%;
  background: none;
  padding-left: 10px;
  color: #808080;
  border: 2px solid #f3f3f3;
}

.form-container {
  padding: 80px 0 0;
  margin: 0 auto;
  display: block;
}

.wpcf7-submit {
  max-width: 200px;
  margin: 0 auto;
  display: block;
  background: #EFB55A;
  transition: 1s;
}

.no-touchevents .wpcf7-submit:hover {
  background: #333333;
  border: 1px solid #333333;
}

div.wpcf7-validation-errors {
  border: 2px solid red !important;
  color: #ffffff;
}

.align-center {
  max-width: 400px;
  display: block;
  padding-bottom: 20px;
}

/*----Blogue------*/
.blogue {
  position: relative;
}

.padding-blog {
  padding: 0 12.2%;
}

.author img {
  max-height: 200px;
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

.post a span {
  color: #EFB55A !important;
  text-decoration: underline;
  transition: 1s;
}

.no-touchevents .post a span:hover {
  color: black !important;
  text-decoration: underline;
}

.author {
  padding: 80px 12.2%;
}

.text-author {
  padding-top: 40px;
}

.text-author p {
  font-size: 14px;
  margin-top: 30px;
}

.text-author b {
  color: #808080;
}

.text-author b span {
  color: #EFB55A;
  font-weight: bold;
  font-size: 1em;
}

.img-blog {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -2;
  bottom: 0;
  left: 0;
}

.img-teaser {
  min-height: 360px;
  width: 100%;
}

.mask-white {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #F5F5F5;
  z-index: -1;
  top: 0;
  left: 0;
  transition: 1s;
}

.info-blog {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid white;
  width: 100%;
  height: 60px;
}

.info-blog svg {
  height: 20px;
  width: 20px;
  position: relative;
}

.view p {
  display: inline-block;
  font-size: 18px;
  color: #808080;
}

.info-blog p {
  display: inline-block;
  margin: 10px 20px 0;
  height: 30px;
  float: left;
}

.cat-home {
  text-transform: uppercase;
  color: #EFB55A;
  font-size: 18px;
  font-weight: bold;
}

.info-blog {
  padding-top: 10px;
}

.view {
  float: right;
  margin-right: 20px;
}

.title-blog {
  margin-left: 12.2%;
}

.flex-3 .blogue {
  background-color: white;
  margin: 0 15px;
}

.more-post .social-share {
  background: transparent !important;
  text-align: left;
}

.more-post, .nav-pagelink {
  padding: 25px 12.2% 55px;
}

.nav-pagelink a {
  font-size: 14px;
  color: #808080;
  border-bottom: 2px solid #808080;
  height: inherit !important;
  width: inherit !important;
}

.no-touchevents .nav-pagelink a:hover {
  color: #EFB55A;
  border-bottom: 2px solid #EFB55A;
}

.prev_post {
  float: left;
}

.next_post {
  float: right;
}

.text-blog {
  position: relative;
  width: 100%;
  transition: 1s;
}

.page-template-blogue .text-blog {
  margin-bottom: 40px;
}

.marge {
  margin-right: 50px !important;
  margin-top: -60px !important;
}

.h2blog {
  margin-left: 12.2%;
  margin-bottom: 60px;
  padding-left: 15px;
  display: inline-block;
}

.button-blogue {
  display: inline-block;
  color: #808080;
  border: 2px solid #EFB55A;
  padding: 13px 35px;
  font-size: 12px;
  top: -5px;
  position: relative;
  margin-left: 50px;
}

.no-touchevents .button-blogue:hover {
  background-color: #EFB55A;
  border: 2px solid #EFB55A;
  color: white;
}

.cat-blog {
  color: #EFB55A;
  text-transform: uppercase;
  font-size: 12px;
}

.no-touchevents .text-blog:hover {
  box-shadow: 0 26px 27px rgba(128, 128, 128, 0.5);
}

.pad-blog {
  padding: 20px;
}

.text-blog h3,
.resume p,
.view p,
.view svg {
  transition: 1s;
}

.text-blog h3 {
  margin-top: 0;
}

.no-touchevents .text-blog:hover .mask-white {
  opacity: 0;
}

.date-mini {
  font-size: 12px;
  color: #808080;
  transition: 1s;
  font-style: italic;
}

.resume {
  color: #000;
}

.resume p {
  color: #808080;
}

.text-blog .btn {
  float: right;
}

.text-blog .circle-social svg, .text-blog .circle-social svg path, .text-blog .circle-social svg path g {
  fill: #808080 !important;
}

.text-blog .circle-social {
  width: auto;
  padding: 0;
}

.text-blog .social-share a {
  width: 30px;
}

.social-share a {
  display: inline-block;
  height: 45px;
  width: 45px;
}

.no-toucehvents .social-share a:hover .circle-social {
  background-color: #333333;
}

.social-share {
  margin: 60px auto;
  display: block;
  text-align: center;
}

.single-post .social-share {
  position: relative;
}

.more-post .social-share {
  position: inherit;
}

.removeone a:first-child {
  display: none;
}

.single .circle-social {
  width: auto;
}

.post {
  padding: 60px 0 0;
}

.post h1 {
  text-align: center;
}

.post-categories {
  text-align: center;
  padding: 0;
}

.post-categories li {
  list-style: none;
}

.more-post img {
  max-width: 300px;
  margin: 0 auto;
  display: block;
}

.resume {
  font-size: 13px;
}

.more-post .swiper-realisation {
  padding-bottom: 23px;
}

.archives {
  padding-top: 60px;
}

.archives #main {
  background-color: #f3f3f3;
  padding: 80px 0;
}

.blog-filter {
  transition: 1s;
}

.blog-filter.is-hidden {
  width: 0;
  height: 0;
  flex: 0;
  opacity: 0;
  visibility: hidden;
}

.title-filter {
  padding-bottom: 60px;
  margin-left: 15px;
}

.filter-blogue ul {
  padding: 0;
}

.filter-blogue ul li {
  display: inline-block;
  list-style: none;
}

.all-filter {
  vertical-align: middle;
  display: inline-block;
  margin-top: 10px;
  margin-left: 25px;
}

.share-single {
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

/*----Boutique------*/
.boutique h2 {
  margin-left: 12.2%;
  margin-bottom: 60px;
}

.bout-product h2 {
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.bout-product .cart-button a {
  left: 0;
}

.bout-product .img-product {
  margin-bottom: 40px;
}

.bout-product .cart-button {
  top: 50% !important;
}

.no-touchevents .bout-product .product:hover .cart-button {
  top: 60% !important;
}

.bout-product {
  max-width: 250px;
  margin: 0 auto;
  display: block;
}

/*.swiper-produit{
	cursor: e-resize!important;
}*/
.boutique img {
  max-width: 300px !important;
  margin: 0 auto !important;
  display: block !important;
  max-height: 250px;
}

.boutique .woocommerce ul.products li.product {
  min-height: auto;
}

.principal {
  height: 340px;
  width: 100%;
  position: relative;
}

.back-principal {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 466px;
  right: 200px;
  z-index: -2;
}

.boutique .swiper-slide {
  width: 300px !important;
}

.boutique .first-slide {
  width: 903px !important;
}

.principal .mask {
  max-width: 625px;
  right: 5px;
  left: initial;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.explorer {
  background-color: #EFB55A;
  position: absolute;
  left: 0;
  height: 122px;
  width: 369px;
  top: 115px;
  padding: 36px 0 0 150px;
  cursor: pointer;
}

.explorer2 {
  background-color: #EFB55A;
  height: 122px;
  width: 369px;
  top: 115px;
  padding: 40px 0 0 60px;
  position: relative;
  cursor: pointer;
}

.explorer2 p {
  color: white;
  display: inline-block;
}

.explorer2 svg {
  transform: rotate(180deg);
  display: inline-block;
  position: relative;
  top: 7px;
  left: -20px;
}

.best-text {
  position: absolute;
  right: 0px;
  height: 100%;
  min-width: 200px;
  background-color: #EFB55A;
  text-align: center;
}

.text-bout {
  padding: 20px;
}

.container_price_best {
  transition: 1s;
}

.no-touchevents .best-text:hover .container_price_best {
  background-color: #EFB55A;
}

.best-text h3,
.best-text p {
  color: white;
}

.text-bout h3 {
  font-size: 18px;
}

.text-bout p {
  font-size: 14px;
}

.text-bout,
.text-bout h3 {
  color: #808080;
}

.logo-product {
  position: relative;
  width: 160px !important;
  top: 30px;
}

.no-touchevents .boutique .product:hover .cart-button a {
  bottom: 25px;
}

.boutique .cart-button {
  margin-top: -138px;
}

.plus-first {
  max-width: 33px;
}

.explorer p {
  color: white;
}

.best-text p {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

.explorer p,
.explorer svg {
  display: inline-block;
}

.reconnaissance {
  background-color: white;
  color: #EFB55A !important;
  padding: 13px 35px;
  font-size: 12px;
  position: relative;
  top: -25px;
}

.principal h3 {
  font-size: 24px;
  margin-top: 0;
}

.cat10 {
  font-size: 10px;
  font-weight: bold;
}

.container_price_best {
  border: 2px solid #f3f3f3;
}

.container_price_best p {
  display: inline-block;
  width: 48%;
  margin: 0;
  text-align: center;
  padding: 10px;
}

.container_price_best p:first-child {
  border-right: 2px solid #f3f3f3;
}

.bout-price span,
.bout-add {
  color: #808080 !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 15px 0;
}

.bout-add {
  border-left: 2px solid #f3f3f3;
  transition: 1s;
}

.container-bout-price {
  border-top: 2px solid #f3f3f3;
  height: 50px;
}

.container-bout-price p {
  margin: 0;
}

.no-touchevents .bout-add:hover {
  background-color: #EFB55A;
  color: white !important;
}

.explorer svg {
  position: relative;
  top: 10px;
  left: 27px;
}

.no-touchevents .explorer:hover > svg {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@keyframes shake-horizontal {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    transform: translateX(-5px);
  }
  20%, 40%, 60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(4px);
  }
  90% {
    transform: translateX(-4px);
  }
}
.bout-price {
  float: left;
  padding: 15px 0;
}

.bout-add {
  float: right;
}

.bout-price,
.bout-add {
  display: inline-block;
  width: 49.4%;
  text-align: center;
}

.bout-add:before,
.add:before {
  content: "\f07a";
  font-family: "FontAwesome";
  width: auto;
  height: auto;
  margin-right: 10px;
}

.white-line {
  height: 65px;
  width: 2px;
  background-color: white;
  margin: 0 auto 20px;
  display: block;
}

/*----Fournisseurs------*/
.fournisseurs {
  max-width: 1200px;
  margin: 80px auto;
  position: relative;
}

.fournisseurs img {
  max-height: 200px;
}

.swiper-fournisseurs .swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/*----Réalisations------*/
.link-filter ul li {
  display: inline-block;
  list-style: none;
  margin-right: 20px;
}

.link-filter {
  padding-top: 60px;
  padding-left: 120px;
}

.js-realisation {
  transition: 0.5s;
}

.js-realisation.is-hidden {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  flex: 0;
}

.all {
  margin-right: -7px;
}

.btn.is-active {
  border-bottom: 2px solid #EFB55A;
}

.filter {
  padding: 0 6%;
}

.realisation-img {
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
}

.text-realisation p {
  font-size: 13px;
}

.realisation-container .btn {
  margin: 0 auto;
  display: block;
  text-align: center;
  max-width: 296px;
}

/*----Infolettre------*/
.infolettre .gform_title,
.infolettre .gfield_label,
.infolettre .gfield_required,
.infolettre .gform_heading {
  display: none;
}

.infolettre .gform_wrapper ul li.gfield {
  margin: 0;
}

.infolettre {
  padding: 60px 0;
  font-size: 14px;
}

.infolettre .gform_wrapper .gform_body,
.infolettre .gform_wrapper .gform_footer {
  width: 49%;
  display: inline-block;
}

.titre_info {
  margin-top: 60px;
}

/*----Points de vente------*/
.option_2,
.option_3 {
  position: relative;
  padding: 100px 0 0;
}

.option_3 {
  padding: 100px 0;
}

.option_2 svg,
.option_3 svg {
  position: absolute;
  left: 3%;
  height: 100px;
}

.store svg {
  position: relative;
  left: initial;
}

.gray-box {
  background-color: #F5F5F5;
  padding: 40px;
}

.cueillette {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.store {
  padding: 80px 0;
}

.button-yellow,
.button,
.woof_submit_search_form,
.woof_reset_search_form {
  border: 2px solid #EFB55A;
  color: #747474;
  font-size: 14px !important;
  height: auto !important;
  font-weight: bold;
  padding: 15px 40px;
  transition: 1s ease-in-out;
}

.no-touchevents .button-yellow:hover,
.no-touchevents .button:hover,
.no-touchevents .woof_submit_search_form:hover,
.no-touchevents .woof_reset_search_form:hover {
  box-shadow: inset 0 -70px 0 -1px #EFB55A;
  color: white;
}

.gray-box p {
  margin-top: 40px;
}

.gray-box h2 {
  margin-bottom: 20px !important;
}

.kind {
  text-transform: uppercase;
  font-weight: bold !important;
  color: #B3B3B3 !important;
  letter-spacing: 2px;
}

.name {
  color: #7F7F7F !important;
}

.store svg {
  margin-top: 40px;
}

.financement {
  background-color: rgba(239, 181, 90, 0.1);
  padding: 150px 0;
}

.photo-financement {
  height: 585px;
  width: 626px;
}

.campagne {
  min-height: 465px;
  background-color: white;
  padding: 40px;
  margin-top: 55px;
  margin-bottom: 20px;
}

.financement .container-button {
  margin-left: 40px;
}

/*----À propos------*/
.pic-cheese {
  float: right;
  margin-bottom: 20px;
}

.container-img {
  position: relative;
  padding-right: 48px;
}

.caseus {
  position: absolute;
  top: 37%;
  left: 18%;
}

.bloc-cheese {
  background-color: rgba(239, 181, 90, 0.1);
  margin-top: 100px;
  padding: 80px 40px 80px 230px;
  position: relative;
}

.col-50 {
  width: 50%;
  position: relative;
  z-index: 3;
  display: inline-block;
}

.cheese {
  position: relative;
  margin: 200px 0;
}

.cheesy {
  position: absolute;
  right: 12.2%;
  top: 0;
  width: 50%;
  z-index: 1;
}

.page-template-a-propos section h2 {
  margin-bottom: 80px;
}

.icon {
  right: 0;
  position: absolute;
  bottom: -18%;
}

.cidres {
  margin: 150px 12.2%;
  padding: 80px 28% 80px 80px;
  max-width: 1183px;
  background-color: rgba(239, 181, 90, 0.1);
  position: relative;
}

.cidre-img {
  position: absolute;
  right: -200px;
  top: 4%;
}

.citation {
  padding: 100px 15% 100px 12.2%;
}

.compotes {
  padding: 100px 12.2% 100px 0;
}

.pic-compote {
  width: 100%;
  height: 584px;
}

.pax {
  background-color: rgba(239, 181, 90, 0.1);
  margin: 100px 0 100px 12.2%;
  padding: 80px 12.2% 80px 80px;
}

.other {
  background-color: #333333;
  padding: 200px 12.2% 100px 0;
  position: relative;
}

.flex-grid {
  display: flex;
  align-content: center;
}

.flex-grid-second {
  display: flex;
  justify-content: space-between;
}

.flex-grid .flexmosaic {
  flex: 1;
}

.mosaic {
  display: inline-block;
  width: 50%;
}

.vinaigrettes {
  height: 619px;
  width: 24%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  position: relative;
  z-index: 2;
}

.carre,
.carre2 {
  height: 338px;
  width: 50%;
  flex: 0 0 49%;
  margin-bottom: 10px;
  text-align: center;
}

.carre {
  margin-right: 5px;
}

.carre2,
.carre {
  margin-left: 5px;
}

.horizontale {
  width: 50%;
  height: 272px;
  flex: 0 0 50%;
  margin-left: 5px;
  text-align: center;
}

.bouclier {
  position: absolute;
  top: 130px;
  left: 7%;
  z-index: 0;
}

.other-title {
  width: 25%;
  display: inline-block;
  position: relative;
  z-index: 3;
  top: -60px;
}

.other-title p {
  width: 56%;
  display: inline-block;
}

.compote-container {
  padding: 40px 150px;
}

.gray-line {
  height: 2px;
  width: 25%;
  background-color: white;
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
}

.other .container-button {
  position: relative;
  top: 20%;
}

.vinaigrettes .container-button {
  top: 40%;
}

.other .button {
  border: 2px solid white;
  color: white;
}

.back-button {
  background-color: rgba(239, 181, 90, 0.8);
  width: 239px;
  margin: 0 auto;
  height: 94px;
  padding-top: 30px;
}

.back-button .button-yellow {
  color: white;
  border: 2px solid white;
}

.bloc-cheese-about {
  padding: 5% 24% 5% 10%;
}

/*Corpo*/
.container80 {
  position: relative;
}

.container80 img {
  position: absolute;
}

.container80 p {
  max-width: 652px;
  min-height: 115px;
}

.texte_gris {
  position: relative;
  top: 40px;
  font-size: 18px;
  line-height: 35px;
  color: #747474;
}

.prix_corpo {
  color: #EFB55A;
  font-size: 25px;
  font-family: "Inknut Antiqua", serif;
  font-weight: normal;
  clear: both;
  margin-bottom: 30px;
}

.container80 ul {
  list-style: none;
  margin-top: 60px;
  padding-left: 25px;
}

ul li.prix_corpo::before {
  content: "•";
  color: #EFB55A;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.yellow-bloc {
  background-color: #EFB55A;
  text-align: center;
  padding: 100px 40px;
}

.yellow-bloc p {
  color: white;
  font-size: 30px;
  line-height: 50px;
  max-width: 1016px;
  margin: 0 auto;
  display: block;
}

.corpo_pax {
  padding: 100px 12%;
}

.corpo_pax img {
  float: right;
}

.corpo_pax p {
  line-height: 35px;
}

.entreprise {
  margin: 0 8%;
}

.entreprise i {
  text-align: center;
  margin: 0 auto;
  display: block;
  max-width: 1088px;
  color: #EFB55A;
  font-size: 40px;
  line-height: 50px;
}

.box-flex {
  display: flex;
  margin-top: 100px;
}

.boxy {
  background-color: rgba(239, 181, 90, 0.1);
  flex-grow: 1;
  margin: 0 20px;
  width: 33.33%;
  max-width: 500px;
  padding: 80px 40px;
  text-align: center;
  min-height: 650px;
}

.boxy h3 {
  min-height: 120px;
  font-size: 40px;
}

.boxy p {
  font-size: 30px;
  color: #EFB55A;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 0;
}

.avantages_corpo {
  margin: 100px 0;
}

.gray-back {
  background-color: #F5F5F5;
  height: 385px;
  width: 667px;
  text-align: right;
  padding: 80px;
}

.list-avantages {
  margin-top: 100px;
  padding: 0;
}

.list-avantages li {
  list-style: none;
  color: #747474;
  text-transform: uppercase;
  font-size: 30px;
  max-width: 665px;
  margin-bottom: 40px;
}

.commander {
  background-color: rgba(239, 181, 90, 0.1);
  padding: 100px 12%;
}

.back-white {
  background-color: white;
  min-height: 583px;
  margin-top: 45px;
  padding: 80px;
}

.back-white h2 {
  margin-bottom: 30px;
  margin-top: 0;
}

.back-white > p {
  color: #747474;
  line-height: 35px;
  font-size: 16px;
  margin-bottom: 30px;
}

.pic-commander {
  height: 692px;
  width: 100%;
}

.aligner {
  text-align: center;
  max-width: 220px;
  margin: 0 auto;
  display: block;
  font-size: 22px;
  color: #747474;
  line-height: 40px;
}

.number-commande {
  font-size: 80px;
  color: #e3e3e5;
  border: 5px solid #e3e3e5;
  border-radius: 50%;
  padding: 0px;
  padding-left: 23px;
  padding-top: 0px;
  height: 102px;
  width: 102px;
  margin: 0 auto 40px;
}

.mobil {
  display: none;
}

.aligner a {
  color: #747474;
}

.no-touchevents .aligner a:hover {
  color: #EFB55A;
}

.etape_img_container {
  border: 5px solid #e3e3e5;
  border-radius: 50%;
  height: 102px;
  width: 102px;
  margin: 0 auto;
  display: block;
  padding: 22px;
}

.rabais_corpo {
  max-width: 1200px;
  color: white;
  margin: 40px auto 0;
  display: block;
}

@media screen and (max-width: 1700px) {
  .texte_gris {
    top: 30px;
  }
}
@media screen and (max-width: 1600px) {
  .padding-content,
  .padding-blog {
    padding: 0 10%;
  }
  .author {
    padding: 40px 10%;
  }
  .cheesy {
    right: 10%;
  }
  .cidres {
    margin: 150px 10%;
    padding: 80px 35% 80px 80px;
  }
  .more-post, .nav-pagelink {
    padding: 25px 10% 55px;
  }
  .h2blog {
    margin-left: 10%;
  }
  .gray-back {
    width: 90%;
  }
  .boxy h3 {
    font-size: 32px;
  }
  .boxy p {
    font-size: 19px;
    line-height: 30px;
  }
  .other {
    padding: 200px 0 100px 0;
  }
  .icon {
    max-height: 300px;
  }
  .yellow-bloc p,
  .entreprise i {
    font-size: 25px;
    line-height: 40px;
  }
  .boxy {
    min-height: auto;
  }
  .list-avantages li {
    font-size: 21px;
  }
  .list-avantages li svg {
    position: relative;
    top: -7px;
    max-width: 40px;
  }
  .aligner {
    font-size: 16px;
    line-height: 31px;
  }
}
@media screen and (max-width: 1400px) {
  .cidres {
    padding: 80px 40% 80px 80px;
  }
}
@media screen and (max-width: 1300px) {
  .form-container {
    max-width: 100%;
    margin: 0 20px;
  }
  .padding-content,
  .padding-blog {
    padding: 0 5%;
  }
  .author {
    padding: 40px 5%;
  }
  .cheesy {
    right: 5%;
  }
  .h2blog {
    margin-left: 5%;
  }
  .more-post, .nav-pagelink {
    padding: 25px 5% 55px;
  }
  .cidres {
    margin: 150px 5% 0;
    padding: 80px 50% 80px 80px;
  }
  .option_2 svg, .option_3 svg {
    position: relative;
  }
  .vinaigrettes,
  .mosaic {
    width: 100%;
  }
  .other {
    padding: 200px 0 100px 0;
  }
  .text-patrimoine {
    padding: 60px 20px;
  }
  .carre, .carre2, .horizontale {
    margin-left: 0;
    margin-bottom: 0;
  }
  .carre {
    margin-right: 0;
  }
  .carre,
  .carre2 {
    flex: 0 0 50%;
  }
  .bloc-cheese {
    padding: 80px 40px 0 90px;
  }
  .compote-container {
    padding: 40px 60px;
  }
  .vinaigrettes {
    background-size: contain !important;
  }
  .boxy h3 {
    font-size: 32px;
  }
}
@media screen and (max-width: 1200px) {
  .container80 img {
    position: relative;
    margin-top: 30px;
  }
  .container80 p {
    max-width: 100%;
    min-height: auto;
  }
  .box-flex {
    display: block;
  }
  .boxy {
    margin: 0 auto 40px;
    display: block;
  }
  .gray-back {
    height: auto;
    width: 400px;
  }
  .pic-commander {
    height: 580px;
  }
  .list-avantages {
    padding: 0 40px;
    margin-top: 50px;
  }
  .entreprise i {
    font-size: 32px;
    line-height: 45px;
  }
  .mobil {
    display: block;
  }
  .desk {
    display: none;
  }
  .boxy {
    width: 100%;
  }
  .back-white {
    margin-top: 0;
  }
}
@media screen and (max-width: 1100px) {
  .boutique {
    padding: 80px 40px 40px;
    text-align: center;
  }
  .cidres {
    padding: 80px 58% 80px 80px;
  }
}
@media screen and (max-width: 1000px) {
  .padding-content,
  .padding-blog {
    padding: 0 20px;
  }
  .author {
    padding: 40px 20px;
  }
  .more-post, .nav-pagelink {
    padding: 25px 20px 55px;
  }
  .cheesy {
    right: 20px;
  }
  .h2blog {
    margin-left: 20px;
  }
  .cidres {
    margin: 150px 20px 0;
  }
  .boutique h2 {
    text-align: left;
  }
}
@media screen and (max-width: 979px) {
  .pad-text, .pad-text-right {
    padding: 40px 50px;
  }
  .membre,
  .blogue {
    margin-bottom: 20px;
  }
  .flex-3,
  .flex-4 {
    flex: 0 0 50%;
  }
  .faq-container {
    padding: 20px 6% 60px;
    position: relative;
  }
  .align-vertical {
    display: block;
  }
  .mobile-appear {
    display: block;
  }
  .mobile-display-none {
    display: none;
  }
  .back-appel,
  .back-splash-service {
    background-attachment: scroll !important;
  }
  .main-holder {
    padding: 0 40px;
  }
  .flexible {
    margin: 50px 0 0;
  }
  .col-mid img {
    margin: 0 auto;
    display: block;
  }
  .explorer {
    width: 200px;
    padding: 36px 0 0 0px;
  }
  .back-principal {
    max-width: initial;
  }
  .first-slide {
    width: 100% !important;
  }
  .campagne {
    min-height: auto;
  }
  .store {
    padding: 60px 0;
    text-align: center;
  }
  .cheesy {
    position: relative;
    right: 0;
    width: 100%;
  }
  .col-50 {
    width: 100%;
  }
  .bloc-cheese {
    margin-top: 0px;
    padding: 80px 40px 0 40px;
  }
  .cidre-img {
    position: relative;
    right: initial;
  }
  .cidres {
    padding: 80px 40px;
    max-width: 100%;
  }
  .container-img {
    padding: 0;
  }
  .boutique .first-slide {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .boutique .woocommerce ul.products[class*=columns-] li.product:nth-child(2n), .boutique .woocommerce-page ul.products[class*=columns-] li.product:nth-child(2n),
  .boutique .woocommerce ul.products[class*=columns-] li.product, .boutique .woocommerce-page ul.products[class*=columns-] li.product {
    float: none !important;
  }
  .boutique .woocommerce ul.products li.product {
    width: 100% !important;
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 720px) {
  .flex-container {
    display: block;
  }
  .flex-3, .flex-4 {
    flex: none;
  }
}
@media screen and (max-width: 640px) {
  .padding {
    padding: 80px 9%;
  }
  .appel {
    padding: 60px 0;
  }
  .membre-container,
  .realisation-container,
  .contact,
  .service-container,
  .padding {
    padding: 60px 20px;
  }
  .col-6 {
    display: block;
    width: 100%;
  }
  .flex-3, .flex-4 {
    flex: none;
  }
  .social-share a {
    height: 35px;
    width: 35px;
  }
  input, select {
    height: 40px;
  }
  #gform_submit_button_1 {
    height: auto;
  }
  .flexible {
    margin: 0;
  }
  .pad-text, .pad-text-right {
    padding: 40px 20px;
  }
  .flex-3 .blogue {
    margin: 0 5px 20px;
  }
  .fournisseurs img {
    max-height: 70px;
  }
  .link-filter {
    padding-left: 0;
  }
  .title-filter {
    padding: 0 20px 50px;
  }
  .filter-blogue ul li {
    margin: 13px 0;
  }
  .max-width-text {
    max-width: 90%;
  }
  .pad-text h2,
  .pad-text-right h2 {
    margin-bottom: 40px;
  }
  .boutique img {
    max-width: 250px !important;
    top: 125px;
  }
  .principal .mask {
    max-width: initial;
    right: 0px;
  }
  .explorer {
    width: 200px;
    padding: 19px 0 0;
    height: 87px;
    top: 25px;
  }
  .best-text {
    position: absolute;
    right: 0px;
    left: 0;
    bottom: 0;
    min-width: 120px;
    height: 25%;
    width: 100%;
  }
  .best-text p {
    margin-top: 10px;
  }
  .white-line {
    display: none;
  }
  .boutique img.logo-product {
    margin: 0 !important;
  }
  .hide-sm {
    display: none;
  }
  .share-single {
    top: -70px;
  }
  .boutique h2 {
    margin-left: 20px;
    margin-bottom: 60px;
  }
  .option_2 svg, .option_3 svg {
    height: 75px;
  }
  .option_2 {
    padding: 40px 0 0;
  }
  .option_3 {
    padding: 40px 0;
  }
  .cueillette img {
    max-height: 140px;
  }
  .photo-financement {
    width: 100%;
    height: 450px;
  }
  .financement {
    padding: 60px 0;
  }
  .container-img {
    padding-right: 0;
  }
  .caseus {
    left: 0;
    max-height: 90px;
  }
  .bloc-cheese {
    padding: 40px 20px;
  }
  .icon {
    right: -100px;
    bottom: -35%;
  }
  .cidres {
    padding: 40px 20px 0;
    margin: 40px 20px 0;
  }
  .page-template-a-propos section h2 {
    margin-bottom: 40px;
  }
  .citation {
    padding: 60px 20px 0;
  }
  .compotes {
    padding: 100px 0 0;
  }
  .pic-compote {
    height: 285px;
  }
  .compote-container {
    padding: 40px 20px;
  }
  .button {
    padding: 15px 35px;
  }
  .pax {
    margin: 0;
    padding: 40px 20px;
  }
  .other {
    padding: 100px 0 50px;
  }
  .vinaigrettes {
    height: 410px;
  }
  .carre, .carre2 {
    width: 100%;
  }
  .flex-grid, .flex-grid-second {
    display: block;
  }
  .horizontale {
    width: 100%;
  }
  .bouclier {
    top: 35px;
    left: initial;
    right: -85px;
    max-height: 200px;
  }
  .other-title p {
    width: 100%;
    margin-left: 40px;
  }
  .bloc-cheese-about {
    padding: 40px 20px;
  }
  .back-principal {
    right: initial;
  }
  .text-formation {
    padding: 40px 5%;
  }
  .back-white {
    margin-top: 0;
    padding: 40px 20px;
    height: auto;
  }
  .back-white h2 {
    margin-bottom: 30px;
    text-align: center;
  }
  .texte_gris {
    top: 0;
  }
  .yellow-bloc {
    padding: 60px 5%;
  }
  .yellow-bloc p {
    font-size: 25px;
    line-height: 35px;
  }
  .corpo_pax {
    padding: 60px 5%;
  }
  .entreprise i {
    font-size: 25px;
    line-height: 35px;
  }
  .boxy h3 {
    min-height: auto;
    font-size: 25px !important;
  }
  .boxy p {
    font-size: 24px;
    line-height: 33px;
  }
  .boxy {
    min-height: auto;
    padding: 40px;
  }
  .gray-back {
    width: 100%;
    text-align: center;
    padding: 60px 0;
  }
  .list-avantages li {
    font-size: 24px;
  }
  .list-avantages li svg {
    position: relative;
    top: -5px;
  }
  .corpo_pax img {
    float: none;
    max-height: 150px;
    margin: 0 auto;
    display: block;
  }
  .container80 img {
    max-height: 150px;
    margin: 0 auto;
    display: block;
  }
  .pic-commander {
    height: 305px;
  }
  .number-commande {
    margin: 0 auto 20px;
  }
  .aligner {
    margin-bottom: 40px;
    line-height: 30px;
    font-size: 19px;
  }
  .list-avantages {
    padding: 0 5%;
  }
  .avantages_corpo {
    margin: 50px 0;
  }
  .box-flex {
    margin-top: 50px;
  }
  .number-commande {
    font-size: 60px;
    padding-left: 18px;
    height: 80px;
    width: 80px;
  }
  .back-white > p {
    line-height: 25px;
    font-size: 13px;
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .home .boutique.padding {
    padding: 60px 20px 0 0;
  }
  .list-avantages li {
    font-size: 21px;
  }
  .boxy p {
    font-size: 20px;
    line-height: 30px;
  }
  .entreprise i {
    font-size: 21px;
    line-height: 29px;
  }
  .corpo_pax p {
    line-height: 25px;
  }
  .yellow-bloc p {
    font-size: 21px;
    line-height: 31px;
  }
  .texte_gris {
    line-height: 28px;
  }
  .cadeau_corpo i {
    line-height: 26px;
  }
}
.thumbnail {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: rgb(30, 30, 30);
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity 0.25s ease, visibility 0s ease 0.25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: "";
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable=true] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color 0.2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */
.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: 0.8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right);
}

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

@supports (padding: max(0px)) {
  /*.fancybox-caption {
      padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left));
  }*/
}
.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }
  .fancybox-slide--image {
    padding: 6px 0;
  }
  .fancybox-close-small {
    right: -6px;
  }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }
  @supports (padding: max(0px)) {
    /*.fancybox-caption {
        padding-left: max(12px, env(safe-area-inset-left));
        padding-right: max(12px, env(safe-area-inset-right));
    }*/
  }
}
/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: 0.5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}
footer {
  width: 100%;
  background-color: #333333;
  padding: 50px 10% 140px;
  position: relative;
}

.copyright {
  position: absolute;
  bottom: 10px;
  left: 10%;
  font-size: 13px;
  color: #808080;
}

.ngv {
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.madeby {
  position: absolute;
  color: #808080;
  bottom: 40px;
  right: 0px;
  left: 0;
  text-align: center;
  font-size: 12px;
}

.propulseby span.ngv {
  font-size: 12px;
  color: #808080;
}

.propulseby {
  position: absolute;
  color: white;
  bottom: 20px;
  right: 0px;
  left: 0;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.btn_retour img {
  transform: rotate(180deg);
}

.btn_retour {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50px;
  top: 58px;
  cursor: pointer;
  transition: 1s;
}

.no-touchevents .btn_retour:hover {
  transform: scale(1.1);
}

.menu-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}

.menu-footer .menu-item {
  flex-grow: 1;
  list-style: none;
  text-align: center;
}

.menu-footer .menu-item:first-child,
.menu-footer .menu-item:nth-child(2),
.menu-footer .menu-item:nth-child(3) {
  text-align: left;
}

.menu-footer .menu-item a {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  color: #7F7F7F;
}

.no-touchevents .menu-footer .menu-item a:hover {
  color: #EFB55A;
}

.logo-footer {
  flex-grow: 2;
  background: url("/wp-content/themes/NGV/assets/src/img/logo-footer.svg") no-repeat 50% 50%;
  background-size: contain;
  height: 150px;
}

.logo-footer a {
  color: #333333 !important;
}

.no-touchevents footer a:hover {
  color: #EFB55A;
}

footer h3,
footer .menu-item-has-children > a {
  text-transform: uppercase;
  color: #808080;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0;
  font-family: "Roboto", sans-serif;
}

footer p {
  font-size: 14px;
  color: white;
  line-height: 26px;
}

.social p {
  color: #808080;
  font-weight: bold;
  font-size: 12px;
}

.flex,
.menu-footer2 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-footer2 .menu-item,
.flexgrow {
  flex-grow: 1;
}

.flexgrow {
  margin-left: 20%;
}

footer .menu-item-has-children {
  list-style: none;
}

footer .menu-item-has-children:after {
  content: "";
}

footer .sub-menu {
  display: block !important;
  background-color: transparent;
}

footer .sub-menu li {
  list-style: none;
}

footer .menu-item-has-children > a {
  pointer-events: none;
}

footer .sub-menu li a {
  color: white;
  font-size: 14px;
}

.menu-footer,
.menu-footer2 {
  padding: 0;
}

.circle-social svg path,
.circle-social svg path g {
  fill: #ffffff !important;
  transition: 1s;
}

.circle-social svg {
  fill: #ffffff !important;
  height: 20px;
  width: 20px;
  transition: 1s;
  margin-right: 20px;
  position: relative;
  top: 4px;
}

.circle-social p {
  transition: 1s;
}

.no-touchevents .circle-social:hover > svg,
.no-touchevents .circle-social:hover > svg path,
.no-touchevents .circle-social:hover > svg path g,
.no-touchevents .circle-social:hover > p {
  fill: #EFB55A !important;
  color: #EFB55A;
}

.circle-social {
  padding: 10px;
  border-radius: 50%;
  height: 40px;
  width: 150px;
  transition: 1s;
}

.circle-social svg,
.circle-social p {
  display: inline-block;
}

.social {
  width: 42%;
  position: absolute;
  bottom: 30px;
  right: 0;
}

.social a {
  display: inline-block;
  margin: 0 15px;
}

.ngv {
  height: 100px !important;
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0px;
}

.menu-footer2 .sub-menu li.menu-item:nth-child(2) {
  margin-top: initial;
}

@media screen and (max-width: 1600px) {
  footer {
    padding: 30px 10% 80px;
  }
  .copyright {
    left: 10%;
  }
  .madeby,
  .propulseby {
    right: 30px;
  }
  .flexgrow {
    margin-left: 2%;
  }
}
@media screen and (max-width: 1300px) {
  footer {
    padding: 30px 5% 80px;
  }
  .copyright {
    left: 5%;
  }
}
@media screen and (max-width: 1200px) {
  .flexgrow {
    margin-left: 0;
  }
  .flexgrow {
    width: 50%;
  }
  .flexgrow, .flexgrow div, .menu-footer2 .menu-item {
    text-align: center !important;
  }
  .ngv {
    left: 0px;
  }
  .menu-footer2 .menu-item {
    width: 50%;
    margin: 0 auto;
    text-align: center;
  }
}
@media screen and (max-width: 1000px) {
  footer {
    padding: 30px 20px 75px;
  }
  .copyright {
    left: 20px;
  }
}
@media screen and (max-width: 979px) {
  .social a {
    margin: 0px;
  }
  .circle-social svg {
    margin-right: 0px;
  }
  .circle-social {
    width: 124px;
  }
}
@media screen and (max-width: 879px) {
  .btn_retour {
    top: 20px;
    width: 35px;
  }
  .copyright {
    margin-top: 52px;
    text-align: center;
  }
  .madeby {
    top: 130px;
    right: 0;
    left: 0;
    text-align: center;
  }
  .propulseby {
    top: 153px;
    right: 0;
    left: 0;
    text-align: center;
  }
  .menu-footer li {
    display: none;
  }
  .menu-footer .logo-footer {
    display: block;
  }
  .menu-footer {
    margin-bottom: 20px;
  }
  .propulseby,
  .madeby {
    top: inherit;
  }
  .copyright {
    position: relative;
    text-align: center;
    left: 0;
    margin-bottom: 30px;
  }
  .social {
    width: 100%;
    position: relative;
  }
  .flexgrow, .menu-footer2 .menu-item {
    width: 100%;
  }
  .social {
    right: 0px;
  }
  .logo-footer {
    height: 115px;
  }
}
@media screen and (max-width: 450px) {
  .flexgrow, .menu-footer2 .menu-item {
    flex-grow: initial;
  }
  .flex, .menu-footer2 {
    display: block;
  }
  .menu-footer2 .menu-item {
    margin-bottom: 30px;
  }
  .menu-footer2 .sub-menu li {
    margin-bottom: 5px;
  }
}
/*
	Utilities
		Typography
*/
.u-txt-center {
  text-align: center !important;
}

.u-txt-right {
  text-align: right !important;
}

.u-txt-left {
  text-align: left !important;
}

/*
	Utilities
		Layout
 */
.u-clear {
  clear: both !important;
  width: 100% !important;
}

.u-pull-right {
  float: right !important;
}

.u-pull-left {
  float: left !important;
}

.u-offset-parent {
  position: relative;
}

.u-overflow-visible {
  overflow: visible !important;
}

/* Visible / Hidden */
.u-hidden {
  display: none !important;
}

.u-visible {
  display: block !important;
}

.u-hidden-xl {
  display: none !important;
}

.u-visible-xl {
  display: block !important;
}

@media screen and (max-width: 1200px) {
  .u-hidden-lg {
    display: none !important;
  }
  .u-visible-lg {
    display: block !important;
  }
}
@media screen and (max-width: 979px) {
  .u-hidden-md {
    display: none !important;
  }
  .u-visible-md {
    display: block !important;
  }
}
@media screen and (max-width: 760px) {
  .u-hidden-sm {
    display: none !important;
  }
  .u-visible-sm {
    display: block !important;
  }
}
@media screen and (max-width: 560px) {
  .u-hidden-xs {
    display: none !important;
  }
  .u-visible-xs {
    display: block !important;
  }
}
.v-home {
  display: block;
  width: 100%;
  /*
  &__other {
    position: relative;
    padding-top: 140px;
    padding-bottom: 140px;
    background-color: #333;

    .other {
      padding-top: 0;
      background-color: transparent;

      &-title {
        top: 0;
        display: inline-flex;
        flex-wrap: wrap;

        p {
          display: block;
          padding-left: 30px;
          text-align: left;
          line-height: 1.5 !important;
          margin-bottom: 30px
        }

        .button-yellow {
          margin-left: calc(25% + 30px);
        }
      }
    }

    .bouclier {
      top: 50%;
    }

    &--marquee {
      display: block;
      width: 100%;
      font-family: Inknut Antiqua, serif;
      font-size: 80px;
      color: #484240;
      margin-bottom: -20px;

      &.top {
        margin-bottom: 0;
      }

      .text {
        position: relative;
        float: left;
        width: auto !important;
        padding: 0 50px;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: -10px;
          display: block;
          width: 20px;
          height: 20px;
          background-color: #484240;
          border-radius: 50%;
        }
      }

      .slick-slider .slick-slide {
        display: inline-block;
        width: auto !important;
      }
    }

    .back-button {
      padding: 30px;
      height: auto;
    }

    .button-yellow {
      display: inline-block;
    }

    @media(max-width: $lg) {

      .gray-line {
        display: none;
      }
      .other-title {
        width: 100%;

        p {
          display: block;
          margin: 0;
          line-height: 1.3 !important;
        }
      }

      .flexmosaic {
        margin-bottom: 30px;
      }

      .mosaic {
        padding: 30px;
      }

      .bouclier {
        display: none;
      }
    }
  }
  */
}
.v-home h2 {
  font-size: 30px;
  margin-bottom: 50px;
}
.v-home p {
  font-size: 18px;
  line-height: 1.9 !important;
  color: #747474;
}
.v-home .o-text-darkMode p {
  color: white;
}
.v-home__row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.v-home__hero {
  position: relative;
  display: block;
  padding-top: 120px;
  color: white;
  background-color: #1D1D1E;
  margin-bottom: -10vw;
}
.v-home__hero--parallax {
  position: relative;
}
.v-home__hero--content {
  position: relative;
  font-size: 40px;
  z-index: 2;
}
.v-home__hero--content .slider--item h2 {
  display: block;
  text-align: center;
  font-weight: 300;
  font-size: 60px;
  color: white;
  text-shadow: 0px 20px 50px #002331;
}
@media (max-width: 767.98px) {
  .v-home__hero--content .slider--item h2 {
    font-size: 30px;
  }
}
.v-home__hero--content .slider--item h2 span {
  display: block;
  margin-top: 10px;
  font-family: Inknut Antiqua, serif;
  font-size: 40px;
  font-weight: 300;
  color: #EFB55A;
}
.v-home__hero--fromage {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -30px;
  z-index: 5;
}
@media (max-width: 991.98px) {
  .v-home__hero--fromage {
    margin-top: 0;
    margin-bottom: -120px;
  }
}
.v-home__hero--fromage img {
  max-width: calc(50% + 15vw);
  margin-right: -15vw;
}
@media (max-width: 991.98px) {
  .v-home__hero--fromage img {
    max-width: 100%;
    margin-right: 0;
    margin-top: 30px;
  }
}
.v-home__degustation {
  position: relative;
  display: block;
  z-index: 2;
}
.v-home__degustation:after {
  content: "";
  position: absolute;
  top: 8vw;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1D1D1E;
  z-index: -4;
}
.v-home__degustation .patch {
  position: absolute;
  top: 150px;
  display: block;
  width: 100%;
  max-width: 500px;
  z-index: 0;
}
@media (max-width: 767.98px) {
  .v-home__degustation .patch {
    max-width: 250px;
    top: 100px;
    right: calc(50% - 125px);
  }
}
.v-home__degustation--content {
  position: relative;
  display: flex;
  align-content: stretch;
  align-items: center;
  gap: 40px;
  padding: 300px 0 150px 0;
  margin-right: -20px;
  margin-left: -20px;
  font-size: 30px;
  color: #EFB55A;
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .v-home__degustation--content {
    flex-wrap: wrap;
  }
}
.v-home__degustation--content h2 {
  margin: 0;
}
.v-home__degustation--content .content {
  display: block;
  width: 100%;
  max-width: 60%;
  padding: 0 20px;
}
@media (max-width: 1199.98px) {
  .v-home__degustation--content .content {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .v-home__degustation--content .content {
    text-align: center;
  }
}
.v-home__degustation--content .buttons {
  display: flex;
  gap: 40px;
  width: 100%;
  max-width: 40%;
  height: 100%;
  padding: 0 20px;
  align-items: flex-start;
  justify-content: flex-end;
}
@media (max-width: 1199.98px) {
  .v-home__degustation--content .buttons {
    max-width: 100%;
    justify-content: flex-start;
  }
}
@media (max-width: 767.98px) {
  .v-home__degustation--content .buttons {
    justify-content: center;
  }
}
@media (max-width: 400px) {
  .v-home__degustation--content .buttons {
    display: block;
  }
  .v-home__degustation--content .buttons .button-yellow {
    display: block;
    text-align: center;
  }
  .v-home__degustation--content .buttons .button-yellow:first-child {
    margin-bottom: 15px;
  }
}
.v-home__degustation--background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
}
.v-home__degustation--background .gradiant {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: linear-gradient(180deg, rgba(29, 29, 30, 0) 0%, #1d1d1e 100%);
  z-index: 3;
}
.v-home__degustation--background img {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
}
.v-home__degustation--fromages {
  display: block;
}
.v-home__degustation--fromages .slick-slide {
  transition: 1s;
}
.v-home__degustation--fromages .slick-current {
  margin-top: -60px;
}
.v-home__degustation--fromages .item {
  display: block;
  padding: 0 20px;
}
.v-home__degustation--fromages .item img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 80px;
}
@media (max-width: 1919.98px) {
  .v-home__produits {
    padding: 0;
  }
  .v-home__produits .padding-content {
    padding: 0;
  }
}
.v-home__produits--row {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.v-home__produits--image, .v-home__produits--content {
  display: block;
  width: 100%;
  max-width: 50%;
}
@media (max-width: 1199.98px) {
  .v-home__produits--image, .v-home__produits--content {
    max-width: 100%;
  }
}
.v-home__produits--image {
  position: relative;
}
.v-home__produits--image .content {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 100%;
  padding: 60px;
  justify-content: flex-end;
  gap: 60px;
  z-index: 2;
}
@media (max-width: 1199.98px) {
  .v-home__produits--image .content {
    padding: 15px;
    gap: 15px;
    justify-content: center;
  }
}
.v-home__produits--image .content--title {
  margin-top: 30px;
  font-size: 40px;
  font-family: Inknut Antiqua, serif;
  font-weight: 300;
}
@media (max-width: 1199.98px) {
  .v-home__produits--image .content--title {
    margin-top: 0;
    font-size: 20px;
  }
}
.v-home__produits--image .content img {
  width: 100%;
  max-width: 140px;
}
@media (max-width: 1199.98px) {
  .v-home__produits--image .content img {
    max-width: 100px;
  }
}
.v-home__produits--image .image {
  position: relative;
  background-color: pink;
  width: 50vw;
  margin-left: -12.2vw;
  padding-top: 106%;
}
@media (max-width: 1919.98px) {
  .v-home__produits--image .image {
    width: 100%;
    margin-left: 0;
  }
}
@media (max-width: 1199.98px) {
  .v-home__produits--image .image {
    padding-top: 50%;
  }
}
.v-home__produits--content {
  position: relative;
  padding: 0 100px 100px;
}
@media (max-width: 1199.98px) {
  .v-home__produits--content {
    padding: 60px 20px;
  }
}
.v-home__produits--content .content--subTitle {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #B3B3B3;
}
.v-home__produits--content .texte {
  padding-right: 100px;
}
@media (max-width: 767.98px) {
  .v-home__produits--content .texte {
    padding-right: 0;
  }
}
.v-home__produits--content .tag {
  position: absolute;
  bottom: 40px;
  right: -40px;
  display: block;
  width: 100%;
  max-width: 200px;
}
@media (max-width: 767.98px) {
  .v-home__produits--content .tag {
    right: 15px;
    bottom: -100px;
  }
}
.v-home__produits--content:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 50vw;
  background-color: #1D1D1E;
  z-index: -1;
}
@media (max-width: 1449.98px) {
  .v-home__produits--content:after {
    width: 100vw;
  }
}
.v-home__aboutUs {
  position: relative;
  display: block;
  padding: 100px 0;
  overflow-x: hidden;
}
.v-home__aboutUs .v-home__row {
  position: relative;
  margin: 0 -80px;
}
.v-home__aboutUs--content, .v-home__aboutUs--images {
  display: block;
  width: 100%;
  max-width: 50%;
  padding: 0 80px;
}
@media (max-width: 991.98px) {
  .v-home__aboutUs--content, .v-home__aboutUs--images {
    max-width: 100%;
  }
}
.v-home__aboutUs--content {
  padding-top: 120px;
}
@media (max-width: 991.98px) {
  .v-home__aboutUs--content {
    padding-top: 0;
  }
}
.v-home__aboutUs--content h2 {
  font-size: 30px;
}
.v-home__aboutUs--content .texte {
  padding-right: 30px;
  margin-bottom: 80px;
}
@media (max-width: 991.98px) {
  .v-home__aboutUs--content .button-yellow {
    display: inline-block;
    margin-bottom: 60px;
  }
}
.v-home__aboutUs--images .image--small, .v-home__aboutUs--images .image--long {
  display: block;
}
.v-home__aboutUs--images .image--small {
  width: calc(100% - 7vw);
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .v-home__aboutUs--images .image--small {
    width: 60vw;
  }
}
.v-home__aboutUs--images .image--long {
  width: calc(50% + 5vw);
  margin-top: -160px;
}
@media (max-width: 991.98px) {
  .v-home__aboutUs--images .image--long {
    margin-top: -80px;
    width: 50vw;
  }
}
.v-home__savoirFaire {
  display: block;
  width: 100%;
  overflow-x: hidden;
}
.v-home__savoirFaire .v-home__row {
  align-items: center;
  margin: 0;
}
.v-home__savoirFaire--content, .v-home__savoirFaire--image {
  display: block;
  width: 100%;
  max-width: 50%;
}
@media (max-width: 1199.98px) {
  .v-home__savoirFaire--content, .v-home__savoirFaire--image {
    max-width: 100%;
  }
}
.v-home__savoirFaire--image img {
  position: relative;
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: -60px;
  margin-left: -100px;
  z-index: 2;
}
@media (max-width: 1919.98px) {
  .v-home__savoirFaire--image img {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .v-home__savoirFaire--image img {
    max-width: 150px;
  }
}
.v-home__savoirFaire--image .image {
  position: relative;
  width: 50vw;
  margin-left: -12.2vw;
  height: 100%;
  padding-top: 80%;
}
@media (max-width: 1199.98px) {
  .v-home__savoirFaire--image .image {
    display: block;
    text-align: center !important;
    width: 100%;
    padding-top: 52.26%;
    margin: 0;
  }
}
.v-home__savoirFaire--content {
  padding-left: 100px;
}
@media (max-width: 991.98px) {
  .v-home__savoirFaire--content {
    padding: 0 !important;
  }
}
.v-home__savoirFaire--content .button-yellow {
  display: inline-block;
  margin-top: 60px;
}
.v-home__blogue {
  position: relative;
  display: block;
  padding: 150px 0;
  width: 100vw;
  overflow-x: hidden;
}
@media (max-width: 767.98px) {
  .v-home__blogue {
    padding: 100px 0;
  }
}
.v-home__blogue h2 {
  color: #6A5A54;
}
.v-home__blogue .v-home__row {
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: flex-start;
}
@media (max-width: 767.98px) {
  .v-home__blogue .v-home__row {
    flex-direction: column-reverse;
  }
}
.v-home__blogue--header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 80px;
}
.v-home__blogue--header h2 {
  margin: 0 60px 0 0;
}
@media (max-width: 991.98px) {
  .v-home__blogue--header {
    display: block;
  }
  .v-home__blogue--header h2 {
    display: block;
    margin-bottom: 30px;
  }
}
.v-home__blogue--content, .v-home__blogue--image {
  position: relative;
  display: block;
  width: 100%;
  max-width: 50%;
  padding: 0 15px;
}
@media (max-width: 767.98px) {
  .v-home__blogue--content, .v-home__blogue--image {
    max-width: 100%;
  }
}
.v-home__blogue--content .post--cat {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #efb55a;
}
.v-home__blogue--content .title {
  font-size: 30px;
}
.v-home__blogue--content .post--footer {
  display: block;
  width: 100%;
}
.v-home__blogue--content .post--footer .post--socials,
.v-home__blogue--content .post--footer .post--button {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 50%;
}
@media (max-width: 767.98px) {
  .v-home__blogue--content .post--footer .post--socials,
  .v-home__blogue--content .post--footer .post--button {
    max-width: 100%;
  }
}
.v-home__blogue--content .post--footer .post--button .btn {
  font-size: 18px;
  margin: 0;
  border: 0;
}
@media (max-width: 767.98px) {
  .v-home__blogue--content .post--footer .post--button .btn {
    display: block;
    padding: 10px 15px;
    border: 2px solid #EFB55A;
  }
}
.v-home__blogue--content .post--footer .post--socials {
  display: flex;
  gap: 15px;
  margin-bottom: 30px;
}
.v-home__blogue--content .post--footer .post--socials a {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-content: center;
}
.v-home__blogue--content .post--footer .post--socials a svg {
  height: 20px;
  fill: #4c4c4c;
}
.v-home__blogue--content .post--footer .post--socials a:hover svg {
  fill: #EFB55A;
}
@media (max-width: 767.98px) {
  .v-home__blogue--content .post--footer .post--button {
    justify-content: flex-start;
    margin-bottom: 60px;
  }
}
.v-home__blogue--image .image {
  position: relative;
  width: 100%;
  padding-top: 69%;
}
@media (max-width: 767.98px) {
  .v-home__blogue--image {
    margin-bottom: 30px;
  }
}
.v-home__blogue--icon {
  position: absolute;
  top: 200px;
  right: -90px;
}
.v-home__blogue--icon img {
  width: 500px;
}
.v-home__otherProducts {
  position: relative;
  padding: 140px 0 200px;
  background-color: #333;
}
.v-home__otherProducts--marquee {
  display: block;
  width: 100%;
  font-family: Inknut Antiqua, serif;
  font-size: 80px;
  color: #484240;
  margin-bottom: -20px;
}
.v-home__otherProducts--marquee.top {
  margin-bottom: 0;
}
.v-home__otherProducts--marquee .text {
  position: relative;
  float: left;
  width: auto !important;
  padding: 0 50px;
}
.v-home__otherProducts--marquee .text:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  display: block;
  width: 20px;
  height: 20px;
  background-color: #484240;
  border-radius: 50%;
}
.v-home__otherProducts--marquee .slick-slider .slick-slide {
  display: inline-block;
  width: auto !important;
}
.v-home__otherProducts--row {
  display: flex;
  flex-wrap: wrap;
}
.v-home__otherProducts--title {
  display: block;
  width: 100%;
  max-width: 20%;
}
.v-home__otherProducts--title .button-yellow {
  display: inline-block;
}
@media (max-width: 1199.98px) {
  .v-home__otherProducts--title {
    max-width: 100%;
    margin-bottom: 60px;
  }
}
.v-home__otherProducts--title h2 {
  font-size: 40px !important;
  color: #efb55a !important;
}
.v-home__otherProducts--title .bouclier {
  width: 100%;
  max-width: 480px;
  top: 55%;
  margin-left: 70px;
}
@media (max-width: 1199.98px) {
  .v-home__otherProducts--title .bouclier {
    display: none;
  }
}
.v-home__otherProducts--images {
  display: block;
  width: 100%;
  max-width: 80%;
}
@media (max-width: 1199.98px) {
  .v-home__otherProducts--images {
    max-width: 100%;
  }
}
.v-home__otherProducts--images .mosaic {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.v-home__otherProducts--images .mosaic--col-1 {
  display: block;
  width: 100%;
  max-width: 33.3333333333%;
}
@media (max-width: 1199.98px) {
  .v-home__otherProducts--images .mosaic--col-1 {
    max-width: 100%;
  }
}
.v-home__otherProducts--images .mosaic--col-1 .mosaic--item {
  padding-top: 167.5%;
}
@media (max-width: 767.98px) {
  .v-home__otherProducts--images .mosaic--col-1 .mosaic--item {
    padding-top: 100%;
  }
}
.v-home__otherProducts--images .mosaic--col-2 {
  width: 100%;
  max-width: 66.6666666667%;
  padding: 0 5px;
}
@media (max-width: 767.98px) {
  .v-home__otherProducts--images .mosaic--col-2 {
    max-width: 100%;
    padding: 0;
  }
}
.v-home__otherProducts--images .mosaic--col-2 .item-col-1, .v-home__otherProducts--images .mosaic--col-2 .item-col-2 {
  width: 100%;
  padding: 0 5px;
}
@media (max-width: 767.98px) {
  .v-home__otherProducts--images .mosaic--col-2 .item-col-1, .v-home__otherProducts--images .mosaic--col-2 .item-col-2 {
    padding: 0;
  }
}
.v-home__otherProducts--images .mosaic--col-2 .item-col-1 .mosaic--item, .v-home__otherProducts--images .mosaic--col-2 .item-col-2 .mosaic--item {
  padding-top: 100%;
}
.v-home__otherProducts--images .mosaic--col-2 .item-col-1 {
  max-width: 50%;
}
@media (max-width: 767.98px) {
  .v-home__otherProducts--images .mosaic--col-2 .item-col-1 {
    max-width: 100%;
    margin-top: 10px;
  }
}
.v-home__otherProducts--images .mosaic--col-2 .item-col-2 {
  max-width: 100%;
  margin-top: 10px;
}
.v-home__otherProducts--images .mosaic--col-2 .item-col-2 .mosaic--item {
  padding-top: 35.204359%;
}
@media (max-width: 767.98px) {
  .v-home__otherProducts--images .mosaic--col-2 .item-col-2 .mosaic--item {
    padding-top: 100%;
  }
}
.v-home__otherProducts--images .mosaic--item {
  position: relative;
  display: block;
  width: 100%;
}
.v-home__goTo {
  position: relative;
  padding-top: 170px;
  background-size: cover;
  background-position: top center;
  z-index: 2;
}
.v-home__goTo--phrases {
  position: relative;
  display: block;
  max-width: 1200px;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .v-home__goTo--phrases {
    margin-bottom: 36px;
  }
}
.v-home__goTo--phrases div {
  display: block;
  font-family: Inknut Antiqua, serif;
  font-size: 80px;
  color: rgba(255, 255, 255, 0.6);
}
.v-home__goTo--phrases div:first-child {
  font-size: 40px;
  color: white;
}
@media (max-width: 991.98px) {
  .v-home__goTo--phrases div {
    font-size: 30px;
    text-align: center;
  }
  .v-home__goTo--phrases div:first-child {
    font-size: 20px;
  }
}
.v-home__goTo--visiteGuidees {
  position: relative;
  left: -240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 420px;
  margin-top: -50px;
  margin-left: auto;
  border-radius: 50%;
  color: white;
  background-color: rgba(239, 181, 90, 0.69);
}
@media (max-width: 991.98px) {
  .v-home__goTo--visiteGuidees {
    left: 0;
  }
}
.v-home__goTo--visiteGuidees h3 {
  display: block;
  font-size: 40px;
  text-align: center;
  color: white;
  margin: 0;
}
.v-home__goTo--visiteGuidees p {
  display: block;
  font-size: 18px;
  color: white;
  margin: 30px 0;
}
.v-home__goTo--visiteGuidees a {
  display: inline-block;
  padding: 15px 30px;
  margin: 0;
  font-size: 16px;
  color: white;
  border: 2px solid white;
}
.v-home__goTo--visiteGuidees a:hover {
  color: #EFB55A;
  background-color: white;
}
@media (max-width: 991.98px) {
  .v-home__goTo--visiteGuidees {
    text-align: center;
    left: 0;
    width: 250px;
    height: 250px;
    font-size: 20px;
    transform: translateY(0);
    margin: 0 auto 60px;
  }
  .v-home__goTo--visiteGuidees p {
    font-size: 14px;
    margin: 15px 0;
  }
}
.v-home__goTo--ctaArret {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 420px;
  margin-top: -90px;
  border-radius: 50%;
  transform: translateY(90px);
  font-family: Inknut Antiqua, serif;
  font-size: 40px;
  text-align: center;
  color: #EFB55A;
  background-color: rgba(106, 90, 84, 0.49);
  border: 10px solid #EFB55A;
}
@media (max-width: 991.98px) {
  .v-home__goTo--ctaArret {
    width: 250px;
    height: 250px;
    font-size: 30px;
    transform: translateY(0);
    margin: 0 auto 60px;
  }
}
@media (max-width: 991.98px) {
  .v-home__goTo {
    padding-bottom: 60px;
  }
}
.v-home .fixOverflow {
  display: block;
  width: 100vw;
  overflow-x: hidden;
}

.v-fromages section {
  overflow-x: hidden;
}
.v-fromages *,
.v-fromages *::before,
.v-fromages *::after {
  box-sizing: border-box;
}
.v-fromages .button-yello {
  display: inline-block;
}
.v-fromages__hero {
  position: relative;
  background-color: #1D1D1E;
  padding-bottom: 150px;
}
.v-fromages__hero .parallax--cheese {
  position: absolute;
  left: calc(50% - 350px);
  top: 25%;
  display: block;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  z-index: 6;
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .v-fromages__hero .parallax--cheese {
    top: 150px;
  }
}
@media (max-width: 767.98px) {
  .v-fromages__hero .parallax--cheese {
    top: 250px;
    max-width: 400px;
    left: calc(50% - 200px);
  }
}
.v-fromages__hero--title {
  position: relative;
  display: block;
  padding: 140px 0 140px 0;
  text-align: center;
}
.v-fromages__hero--title h1 {
  margin: 0 0 200px 0;
  font-size: 50px;
}
@media (max-width: 991.98px) {
  .v-fromages__hero--title h1 {
    font-size: 40px;
  }
}
@media (max-width: 767.98px) {
  .v-fromages__hero--title h1 {
    font-size: 30px;
  }
}
.v-fromages__hero--content {
  position: relative;
  display: block;
  overflow: hidden;
}
.v-fromages__hero--content .small--container {
  position: relative;
  display: block;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 300px 30px 0;
  z-index: 5;
}
@media (max-width: 767.98px) {
  .v-fromages__hero--content .small--container {
    padding: 150px 30px 0;
  }
}
.v-fromages__hero--content .small--container .logos {
  display: block;
  margin: 80px auto 0 auto;
}
.v-fromages__hero--content .content {
  display: block;
  text-align: center;
  margin-bottom: 160px;
}
.v-fromages__hero--content .content p {
  color: white !important;
  line-height: 1.8;
}
.v-fromages__hero--content .sansLactose {
  display: block;
  font-size: 40px;
  font-style: italic;
  text-align: center;
}
.v-fromages__hero--content .sansLactose p {
  color: #EFB55A !important;
  line-height: 1.8;
}
.v-fromages__hero--content .background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
}
.v-fromages__hero--content .background .gradiant {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(29, 29, 30, 0) 0%, #1d1d1e 90%);
  z-index: 3;
}
.v-fromages__hero--content .background img {
  position: relative;
  width: 100%;
  z-index: 2;
}
.v-fromages__liste {
  position: relative;
  display: block;
  padding-bottom: 180px;
}
@media (max-width: 767.98px) {
  .v-fromages__liste {
    padding-bottom: 0;
  }
}
.v-fromages__liste--cat {
  display: block;
  padding-top: 180px;
}
@media (max-width: 767.98px) {
  .v-fromages__liste--cat {
    padding-top: 130px;
  }
}
.v-fromages__liste--cat .slick-track {
  display: flex !important;
  pointer-events: none;
}
.v-fromages__liste--cat h2.cat-title {
  display: block;
  font-size: 114px;
  color: #E3E3E5;
  margin: 0 0 14px 0;
}
@media (max-width: 991.98px) {
  .v-fromages__liste--cat h2.cat-title {
    margin-bottom: 25px;
    font-size: 60px;
  }
}
@media (max-width: 767.98px) {
  .v-fromages__liste--cat h2.cat-title {
    font-size: 50px;
    margin-bottom: 30px;
  }
}
.v-fromages__liste--cat h2.cat-title .text {
  text-wrap: nowrap;
}
.v-fromages__liste--cat h2.cat-title .text:after {
  content: "•";
  padding: 0 20px;
}
.v-fromages__liste--cat.o-firstItem {
  position: relative;
  padding: 180px 0;
  background-color: #1D1D1E;
}
@media (max-width: 767.98px) {
  .v-fromages__liste--cat.o-firstItem {
    padding: 90px 0 15px 0;
  }
}
.v-fromages__liste--cat.o-firstItem h2.cat-title {
  color: #484240;
}
.v-fromages__liste--cat.o-firstItem .feuille_parallax {
  position: absolute;
  right: -400px;
  top: 50%;
  display: block;
  width: 100%;
  max-width: 800px;
  transform: translateY(-50%);
  opacity: 0.15;
}
.v-fromages__liste--cat .feuille_parallax {
  position: absolute;
  right: -400px;
  top: 50%;
  display: block;
  width: 100%;
  max-width: 800px;
  opacity: 0.15;
}
.v-fromages__boutique {
  position: relative;
  display: block;
  padding: 250px 0 150px 0;
}
@media (max-width: 767.98px) {
  .v-fromages__boutique {
    padding: 0px 0 75px 0;
  }
}
.v-fromages__boutique .feuille_parallax {
  position: absolute;
  top: -100px;
  left: 0;
  opacity: 0.2;
  z-index: -1;
}
.v-fromages__boutique--heures {
  display: block;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 60px auto;
  text-align: center;
}
.v-fromages__boutique--heures .image {
  position: relative;
  display: block;
  padding-bottom: 60%;
}
.v-fromages__boutique--heures h2 {
  font-size: 60px;
  margin: 0 0 100px 0;
}
@media (max-width: 991.98px) {
  .v-fromages__boutique--heures h2 {
    font-size: 30px;
  }
}
.v-fromages__boutique--heures .box {
  position: relative;
  display: block;
  padding: 60px;
  font-size: 18px;
  color: #747474;
  background-color: #F5F5F5;
}
@media (max-width: 991.98px) {
  .v-fromages__boutique--heures .box {
    padding: 60px 30px;
  }
}
.v-fromages__boutique--heures .box .title {
  display: block;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #EFB55A;
}
.v-fromages__boutique--heures .box .heures--small {
  display: block;
  margin-top: 40px;
  font-size: 14px;
}
.v-fromages__boutique--heures .box .parallax {
  position: absolute;
  bottom: -150px;
  right: -150px;
  display: block;
  width: 100%;
  max-width: 300px;
}
@media (max-width: 991.98px) {
  .v-fromages__boutique--heures .box .parallax {
    max-width: 200px;
    bottom: -100px;
    right: -100px;
  }
}
.v-fromages__boutique--adresse {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.v-fromages__boutique--adresse .title {
  display: block;
  margin-bottom: 50px;
  font-size: 30px;
  color: #747474;
}
.v-fromages__boutique--adresse address {
  display: block;
  margin-bottom: 50px;
  text-align: center;
  font-style: normal;
  font-size: 18px;
}
.v-fromages__boutique .button-yellow {
  display: inline-block;
}
.v-fromages__degustation {
  position: relative;
  display: block;
  padding: 150px 0;
  background-color: rgba(239, 181, 90, 0.1);
}
@media (max-width: 767.98px) {
  .v-fromages__degustation {
    padding: 75px 0;
  }
}
.v-fromages__degustation--row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .v-fromages__degustation--row {
    align-items: stretch;
  }
}
@media (max-width: 767.98px) {
  .v-fromages__degustation--row {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.v-fromages__degustation--content, .v-fromages__degustation--image {
  position: relative;
  display: block;
  width: 100%;
  max-width: 50%;
}
@media (max-width: 767.98px) {
  .v-fromages__degustation--content, .v-fromages__degustation--image {
    max-width: 100%;
  }
}
.v-fromages__degustation--content {
  display: block;
}
.v-fromages__degustation--content .box {
  display: block;
  padding: 80px 60px;
  margin-bottom: 60px;
  font-size: 25px;
  line-height: 1.8;
  color: #747474;
  background-color: white;
}
.v-fromages__degustation--content .box h2 {
  margin-top: 0;
}
.v-fromages__degustation--content .box p:last-child {
  margin-bottom: 0;
}
@media (max-width: 1199.98px) {
  .v-fromages__degustation--content .box {
    font-size: 18px;
    padding: 60px;
    margin-bottom: 30px;
    height: calc(100% - 90px);
  }
}
.v-fromages__degustation--content .box-btn {
  display: block;
  padding-left: 60px;
}
.v-fromages__degustation--content .box-btn .button-yellow {
  display: inline-block;
}
.v-fromages__degustation--image {
  position: relative;
}
.v-fromages__degustation--image .image {
  position: relative;
  display: block;
  padding-top: 106%;
}

.c-fromage {
  position: relative;
  display: block;
}
.c-fromage.fromage--droit .c-fromage__row {
  flex-direction: row-reverse;
}
.c-fromage__row {
  display: flex;
  flex-wrap: wrap;
  margin: -60px;
  align-items: center;
  padding: 24px 0;
}
@media (max-width: 767.98px) {
  .c-fromage__row {
    margin-bottom: 6rem;
  }
}
.c-fromage__image, .c-fromage__content {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 50%;
  padding: 0 60px;
}
.c-fromage__image .o-darkMode p, .c-fromage__content .o-darkMode p {
  color: white;
}
@media (max-width: 767.98px) {
  .c-fromage__image, .c-fromage__content {
    max-width: 100%;
  }
}
.c-fromage__content {
  line-height: 1.8rem;
}
.c-fromage__content--description {
  display: block;
  margin-bottom: 50px;
  font-size: 18px;
  color: #747474;
}
.c-fromage__content--description p:last-child {
  margin-bottom: 0;
}
.c-fromage__content ul {
  display: block;
  padding: 0;
  margin: 0 0 50px 0;
  list-style: none;
}
.c-fromage__content ul li {
  color: #EFB55A;
}
.c-fromage__content ul li.title {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #B3B3B3;
}

.o-text-darkMode.o-darkMode,
.button-yellow.o-darkMode {
  color: white;
}

pre.checkCa {
  position: relative;
  display: block;
  padding: 15px;
  font-size: 14px;
  color: white;
  background-color: black;
  z-index: 3;
}