/*
	Utilities
		Typography
*/
.u-txt-center {
	text-align: center !important;
}
.u-txt-right {
	text-align: right !important;
}
.u-txt-left {
	text-align: left !important;
}