// Mixins
@import 'mixins/layout';
@import 'mixins/colors';

// Modules
@import 'modules/wordpress';
@import 'modules/contenu';
@import 'modules/fancybox';
@import 'modules/footer';

// Utilities
@import 'utilities/typography';
@import 'utilities/layout';

// Views
@import 'views/home';
@import 'views/fromages';
@import 'utilities/darkMode';


pre.checkCa {
  position: relative;
  display: block;
  padding: 15px;
  font-size: 14px;
  color: white;
  background-color: black;
  z-index: 3;
}
