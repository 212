//MIXINS
//Colors
$whity: #ffffff;
$black: #000;
$primary: #EFB55A;
$secondary: #333333;
$background: #FFFFFF;
$gray: #4c4c4c;
$graylight: #f3f3f3;
$graydark: #808080;
$graypoint: #747474;

//V2 Colors

$blackAlt: #1D1D1E;
$grayLighter: #B3B3B3;
