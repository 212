/*
	COMPONENTS
		Wordpress
*/
/* Images */
img.alignnone,
.wp-caption.alignnone,
img.alignleft,
.wp-caption.alignleft,
img.alignright,
.wp-caption.alignright {

}

img.alignnone,
.wp-caption.alignnone,
img.alignleft,
.wp-caption.alignleft {
	float: left;
	margin: 0 1.1em 1.1em 0;
}
img.alignright,
.wp-caption.alignright {
	float: right;
	margin: 0 0 1.1em 1.1em;
}
img.aligncenter,
.wp-caption.aligncenter {
	display: block;
	margin: 0 auto 1.1em;
}
.wp-caption-text {
	font-size: 0.75em;
	margin: 1.45em 0;
}
[id^="attachment_"] {
	width: auto !important;
}