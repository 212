.padding-content {
  padding: 0 12.2%;
}


.home .main-holder {
  background-color: $graylight;
  padding: 120px 0;
}

.home .main-holder .btn {
  margin-top: -40px;
}

/*----Texte et photo------*/
.pad-text,
.pad-text-right {
  padding: 40px;
}

.pad-text h2,
.pad-text-right h2 {
  margin-bottom: 60px;
}

.flex-container {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.col-mid {
  flex: 1;
}

.flex-2 {
  flex: 0 0 50%;
}

.flex-3 {
  flex: 0 0 33.33%;
}

.flex-4 {
  flex: 0 0 25%;
}

.mobile-appear {
  display: none;
}

.border {
  width: 90px;
  height: 2px;
  background-color: $primary;
  margin: 0 auto 30px;
  display: block;
}

/*----Texte------*/

.padding {
  padding: 20px 0;
}

/*----Appel a l'action------*/

.appel {
  position: relative;
  min-height: 450px;
  width: 100%;
  text-align: center;
  padding: 80px;
}

.appel h2 {
  color: $whity;
}

.back-appel {
  position: absolute;
  height: 100%;
  width: 100%;
  background-attachment: fixed !important;
  z-index: -2;
  top: 0;
  left: 0;
}

.mask-grey {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .4);
  z-index: -1;
  top: 0;
  left: 0;
}

.max-width-text {
  max-width: 50%;
  margin: 0 auto 40px;
  display: block;
}

.max-width-text p {
  color: $whity;
}

.submit {
  background-color: $primary !important;
  padding: 10px 25px;
  color: $whity !important;
  border-radius: 0 !important;
}

.btn {
  color: $graydark;
  margin: 10px 25px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}

.no-touchevents .btn:hover {
  border-bottom: 2px solid $primary;
  color: $primary;
}


.no-touchevents .submit:hover {
  background-color: $secondary !important;
  color: $whity !important;
}

/*----Équipe------*/

.membre-container {
  padding: 80px;
}

.membre {
  text-align: center;
  padding: 0 10px;
}

.membre img {
  max-height: 100%;
}

.service-container .membre img {
  max-height: 125px;
}

.name-membre {
  margin-top: 30px;
  text-transform: uppercase;
  color: $black;
}

.uppercase {
  text-transform: uppercase;
}

.social-person a {
  display: inline-block;
}

/*----Carrousel------*/
.carrousel,
.swiper-carrousel {
  width: 100%;
  height: 300px;
}

/*----témoignages------*/

.person {
  max-height: 300px;
  margin-bottom: 20px;
}

.tem {
  padding: 20px 25%;
}

.tem img {
  display: inline-block;
}

.temoignage {
  display: inline-block;
  margin-left: 40px;
  vertical-align: top;
}

.temoignage p {
  margin: 0;
}

.tem i {
  color: $secondary;
  float: right;
}

/*----Services------*/

.service-container {
  position: relative;
  padding: 80px;
}

.service-container h2 {
  text-align: center;
}

.service-container p {
  color: black;
  font-size: 16px;
}

.name-membre {
  font-size: 18px;
}

.back-splash-service {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background-attachment: fixed !important;
}

.service-container .mask {
  z-index: -1;
}

/*----FAQ------*/

.faq-container {
  padding: 120px 20%;
  position: relative;
}

.faq {
  margin-top: 40px;
}

.q {
  background-color: $primary;
  padding: 10px 20px;
  color: white;
  list-style: none;
  cursor: pointer;
  position: relative;
}

.a {
  background-color: $secondary;
  padding: 10px 20px;
  color: white;
  list-style: none;
  display: none;
}

.arrow {
  height: 20px;
  width: 25px;
  position: absolute;
  right: 10px;
  transition: 1s;
}

.arrow.rotate {
  transform: rotate(180deg);
}

.interrogation {
  position: absolute;
  bottom: -50px;
  right: -50px;
  transform: scale(0.7);
  opacity: 0.3;
  z-index: -1;
}


/*----Réalisation------*/
.realisation-container {
  padding: 80px;
}

.real {
  text-align: center;
  padding: 20px;
}

.swiper-slide .text-realisation {
  padding: 40px 40px 0;
  opacity: 0;
  transition: 1s;
}

.swiper-slide-active .text-realisation {
  opacity: 1;
}

.title-realisation {
  text-transform: uppercase;
  color: $primary;
}

/*----Contact------*/

.contact {
  padding: 80px 12%;
}

.col-6 {
  display: inline-block;
  width: 49%;
}

input {
  width: 98%;
  height: 40px;
  padding-left: 10px;
  background: none;
  color: $whity;
  border: 1px solid $primary;
}

select {
  height: 40px;
  padding-left: 10px;
  background: none;
  color: $whity;
  border: 1px solid $primary;
}

.qty {
  height: 60px;
}

option {
  color: black;
}

textarea {
  width: 98%;
  background: none;
  padding-left: 10px;
  color: $graydark;
  border: 2px solid $graylight;
}

.form-container {
  padding: 80px 0 0;
  margin: 0 auto;
  display: block;
}

.wpcf7-submit {
  max-width: 200px;
  margin: 0 auto;
  display: block;
  background: $primary;
  transition: 1s;
}

.no-touchevents .wpcf7-submit:hover {
  background: $secondary;
  border: 1px solid $secondary;
}

div.wpcf7-validation-errors {
  border: 2px solid red !important;
  color: $whity;
}

.align-center {
  max-width: 400px;
  display: block;
  padding-bottom: 20px;
}

/*----Blogue------*/

.blogue {
  position: relative;
}

.padding-blog {
  padding: 0 12.2%;
}

.author img {
  max-height: 200px;
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

.post a span {
  color: $primary !important;
  text-decoration: underline;
  transition: 1s;
}

.no-touchevents .post a span:hover {
  color: black !important;
  text-decoration: underline;
}

.author {
  padding: 80px 12.2%;
}

.text-author {
  padding-top: 40px;
}

.text-author p {
  font-size: 14px;
  margin-top: 30px;
}

.text-author b {
  color: $graydark;
}

.text-author b span {
  color: $primary;
  font-weight: bold;
  font-size: 1em;
}

.img-blog {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -2;
  bottom: 0;
  left: 0;
}

.img-teaser {
  min-height: 360px;
  width: 100%;
}

.mask-white {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #F5F5F5;
  z-index: -1;
  top: 0;
  left: 0;
  transition: 1s;
}

.info-blog {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid white;
  width: 100%;
  height: 60px;
}

.info-blog svg {
  height: 20px;
  width: 20px;
  position: relative;
}

.view p {
  display: inline-block;
  font-size: 18px;
  color: $graydark;
}

.info-blog p {
  display: inline-block;
  margin: 10px 20px 0;
  height: 30px;
  float: left;
}

.cat-home {
  text-transform: uppercase;
  color: $primary;
  font-size: 18px;
  font-weight: bold;
}

.info-blog {
  padding-top: 10px;
}

.view {
  float: right;
  margin-right: 20px;
}

.title-blog {
  margin-left: 12.2%;
}

.flex-3 .blogue {
  background-color: white;
  margin: 0 15px;
}

.more-post .social-share {
  background: transparent !important;
  text-align: left;
}

.more-post, .nav-pagelink {
  padding: 25px 12.2% 55px;
}

.nav-pagelink a {
  font-size: 14px;
  color: $graydark;
  border-bottom: 2px solid $graydark;
  height: inherit !important;
  width: inherit !important;
}

.no-touchevents .nav-pagelink a:hover {
  color: $primary;
  border-bottom: 2px solid $primary;
}

.prev_post {
  float: left;
}

.next_post {
  float: right;
}

.text-blog {
  position: relative;
  width: 100%;
  transition: 1s;
}

.page-template-blogue .text-blog {
  margin-bottom: 40px;
}

.marge {
  margin-right: 50px !important;
  margin-top: -60px !important;
}

.h2blog {
  margin-left: 12.2%;
  margin-bottom: 60px;
  padding-left: 15px;
  display: inline-block;
}

.button-blogue {
  display: inline-block;
  color: $graydark;
  border: 2px solid $primary;
  padding: 13px 35px;
  font-size: 12px;
  top: -5px;
  position: relative;
  margin-left: 50px;
}

.no-touchevents .button-blogue:hover {
  background-color: $primary;
  border: 2px solid $primary;
  color: white;
}

.cat-blog {
  color: $primary;
  text-transform: uppercase;
  font-size: 12px;
}

.no-touchevents .text-blog:hover {
  box-shadow: 0 26px 27px rgba($graydark, 0.5);
}

.pad-blog {
  padding: 20px;
}

.text-blog h3,
.resume p,
.view p,
.view svg {
  transition: 1s;
}

.text-blog h3 {
  margin-top: 0;
}

.no-touchevents .text-blog:hover .mask-white {
  opacity: 0;
}


.date-mini {
  font-size: 12px;
  color: $graydark;
  transition: 1s;
  font-style: italic;
}

.resume {
  color: $black;
}

.resume p {
  color: $graydark;
}

.text-blog .btn {
  float: right;
}

.text-blog .circle-social svg, .text-blog .circle-social svg path, .text-blog .circle-social svg path g {
  fill: $graydark !important;
}

.text-blog .circle-social {
  width: auto;
  padding: 0;
}

.text-blog .social-share a {
  width: 30px;
}

.social-share a {
  display: inline-block;
  height: 45px;
  width: 45px;
}

.no-toucehvents .social-share a:hover .circle-social {
  background-color: $secondary;
}

.social-share {
  margin: 60px auto;
  display: block;
  text-align: center;

}

.single-post .social-share {
  position: relative;
}

.more-post .social-share {
  position: inherit;
}

.removeone a:first-child {
  display: none;
}

.single .circle-social {
  width: auto;
}

.post {
  padding: 60px 0 0;
}

.post h1 {
  text-align: center;
}

.post-categories {
  text-align: center;
  padding: 0;
}

.post-categories li {
  list-style: none;
}


.more-post img {
  max-width: 300px;
  margin: 0 auto;
  display: block;
}

.resume {
  font-size: 13px;
}

.more-post .swiper-realisation {
  padding-bottom: 23px;
}

.archives {
  padding-top: 60px;
}

.archives #main {
  background-color: $graylight;
  padding: 80px 0;
}

.blog-filter {
  transition: 1s;
}

.blog-filter.is-hidden {
  width: 0;
  height: 0;
  flex: 0;
  opacity: 0;
  visibility: hidden;
}

.title-filter {
  padding-bottom: 60px;
  margin-left: 15px;
}

.filter-blogue ul {
  padding: 0;
}

.filter-blogue ul li {
  display: inline-block;
  list-style: none;
}

.all-filter {
  vertical-align: middle;
  display: inline-block;
  margin-top: 10px;
  margin-left: 25px;
}

.share-single {
  position: absolute;
  left: 0;
  right: 0;
  top: 35px;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

/*----Boutique------*/

.boutique h2 {
  margin-left: 12.2%;
  margin-bottom: 60px;
}

.bout-product h2 {
  font-size: 12px;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.bout-product .cart-button a {
  left: 0;
}

.bout-product .img-product {
  margin-bottom: 40px;
}

.bout-product .cart-button {
  top: 50% !important;
}

.no-touchevents .bout-product .product:hover .cart-button {
  top: 60% !important;
}

.bout-product {
  max-width: 250px;
  margin: 0 auto;
  display: block;
}

/*.swiper-produit{
	cursor: e-resize!important;
}*/
.boutique img {
  max-width: 300px !important;
  margin: 0 auto !important;
  display: block !important;
  max-height: 250px;
}

.boutique .woocommerce ul.products li.product {
  min-height: auto;
}

.principal {
  height: 340px;
  width: 100%;
  position: relative;
}

.back-principal {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 466px;
  right: 200px;
  z-index: -2;
}

.boutique .swiper-slide {
  width: 300px !important;
}

.boutique .first-slide {
  width: 903px !important;
}

.principal .mask {
  max-width: 625px;
  right: 5px;
  left: initial;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.explorer {
  background-color: $primary;
  position: absolute;
  left: 0;
  height: 122px;
  width: 369px;
  top: 115px;
  padding: 36px 0 0 150px;
  cursor: pointer;
}

.explorer2 {
  background-color: $primary;
  height: 122px;
  width: 369px;
  top: 115px;
  padding: 40px 0 0 60px;
  position: relative;
  cursor: pointer;
}

.explorer2 p {
  color: white;
  display: inline-block;
}

.explorer2 svg {
  transform: rotate(180deg);
  display: inline-block;
  position: relative;
  top: 7px;
  left: -20px;
}

.best-text {
  position: absolute;
  right: 0px;
  height: 100%;
  min-width: 200px;
  background-color: $primary;
  text-align: center;
}

.text-bout {
  padding: 20px;
}

.container_price_best {
  transition: 1s;
}

.no-touchevents .best-text:hover .container_price_best {
  background-color: $primary;
}

.best-text h3,
.best-text p {
  color: white;
}

.text-bout h3 {
  font-size: 18px;
}

.text-bout p {
  font-size: 14px;
}

.text-bout,
.text-bout h3 {
  color: $graydark;
}

.logo-product {
  position: relative;
  width: 160px !important;
  top: 30px;
}

.no-touchevents .boutique .product:hover .cart-button a {
  bottom: 25px;
}

.boutique .cart-button {
  margin-top: -138px;
}

.plus-first {
  max-width: 33px;
}

.explorer p {
  color: white;
}

.best-text p {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
}

.explorer p,
.explorer svg {
  display: inline-block;
}

.reconnaissance {
  background-color: white;
  color: $primary !important;
  padding: 13px 35px;
  font-size: 12px;
  position: relative;
  top: -25px;
}

.principal h3 {
  font-size: 24px;
  margin-top: 0;
}

.cat10 {
  font-size: 10px;
  font-weight: bold;
}

.container_price_best {
  border: 2px solid $graylight;
}

.container_price_best p {
  display: inline-block;
  width: 48%;
  margin: 0;
  text-align: center;
  padding: 10px;
}

.container_price_best p:first-child {
  border-right: 2px solid $graylight;
}

.bout-price span,
.bout-add {
  color: $graydark !important;
  font-weight: bold !important;
  font-size: 16px !important;
  padding: 15px 0;
}

.bout-add {
  border-left: 2px solid $graylight;
  transition: 1s;
}


.container-bout-price {
  border-top: 2px solid $graylight;
  height: 50px;
}

.container-bout-price p {
  margin: 0;
}

.no-touchevents .bout-add:hover {
  background-color: $primary;
  color: white !important;
}

.explorer svg {
  position: relative;
  top: 10px;
  left: 27px;
}


.no-touchevents .explorer:hover > svg {
  animation: shake-horizontal 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@keyframes shake-horizontal {
  0%,
  100% {

    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {

    transform: translateX(-5px);
  }
  20%,
  40%,
  60% {

    transform: translateX(5px);
  }
  80% {

    transform: translateX(4px);
  }
  90% {

    transform: translateX(-4px);
  }
}


.bout-price {
  float: left;
  padding: 15px 0;
}

.bout-add {
  float: right;
}

.bout-price,
.bout-add {
  display: inline-block;
  width: 49.4%;
  text-align: center;
}

.bout-add:before,
.add:before {
  content: "\f07a";
  font-family: 'FontAwesome';
  width: auto;
  height: auto;
  margin-right: 10px;
}

.white-line {
  height: 65px;
  width: 2px;
  background-color: white;
  margin: 0 auto 20px;
  display: block;
}


/*----Fournisseurs------*/

.fournisseurs {
  max-width: 1200px;
  margin: 80px auto;
  position: relative;
}

.fournisseurs img {
  max-height: 200px;
}

.swiper-fournisseurs .swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/*----Réalisations------*/
.link-filter ul li {
  display: inline-block;
  list-style: none;
  margin-right: 20px;
}

.link-filter {
  padding-top: 60px;
  padding-left: 120px;
}

.js-realisation {
  transition: 0.5s;
}

.js-realisation.is-hidden {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  flex: 0;
}

.all {
  margin-right: -7px;
}

.btn.is-active {
  border-bottom: 2px solid $primary;
}

.filter {
  padding: 0 6%;
}

.realisation-img {
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
}

.text-realisation p {
  font-size: 13px;
}

.realisation-container .btn {
  margin: 0 auto;
  display: block;
  text-align: center;
  max-width: 296px;
}


/*----Infolettre------*/

.infolettre .gform_title,
.infolettre .gfield_label,
.infolettre .gfield_required,
.infolettre .gform_heading {
  display: none;
}

.infolettre .gform_wrapper ul li.gfield {
  margin: 0;
}

.infolettre {
  padding: 60px 0;
  font-size: 14px;
}

.infolettre .gform_wrapper .gform_body,
.infolettre .gform_wrapper .gform_footer {
  width: 49%;
  display: inline-block;
}

.titre_info {
  margin-top: 60px;
}

/*----Points de vente------*/

.option_2,
.option_3 {
  position: relative;
  padding: 100px 0 0;
}

.option_3 {
  padding: 100px 0;
}

.option_2 svg,
.option_3 svg {
  position: absolute;
  left: 3%;
  height: 100px;
}

.store svg {
  position: relative;
  left: initial;
}

.gray-box {
  background-color: #F5F5F5;
  padding: 40px;
}

.cueillette {
  margin: 0 auto;
  display: block;
  text-align: center;
}

.store {
  padding: 80px 0;
}

.button-yellow,
.button,
.woof_submit_search_form,
.woof_reset_search_form {
  border: 2px solid $primary;
  color: $graypoint;
  font-size: 14px !important;
  height: auto !important;
  font-weight: bold;
  padding: 15px 40px;
  transition: 1s ease-in-out;
}

.no-touchevents .button-yellow:hover,
.no-touchevents .button:hover,
.no-touchevents .woof_submit_search_form:hover,
.no-touchevents .woof_reset_search_form:hover {
  box-shadow: inset 0 -70px 0 -1px $primary;
  color: white;
}

.gray-box p {
  margin-top: 40px;
}

.gray-box h2 {
  margin-bottom: 20px !important;
}

.kind {
  text-transform: uppercase;
  font-weight: bold !important;
  color: #B3B3B3 !important;
  letter-spacing: 2px;
}

.name {
  color: #7F7F7F !important;
}

.store svg {
  margin-top: 40px;
}

.financement {
  background-color: rgba($primary, 0.10);
  padding: 150px 0;
}

.photo-financement {
  height: 585px;
  width: 626px;
}

.campagne {
  min-height: 465px;
  background-color: white;
  padding: 40px;
  margin-top: 55px;
  margin-bottom: 20px;
}

.financement .container-button {
  margin-left: 40px;
}

/*----À propos------*/
.pic-cheese {
  float: right;
  margin-bottom: 20px;
}

.container-img {
  position: relative;
  padding-right: 48px;
}

.caseus {
  position: absolute;
  top: 37%;
  left: 18%;
}

.bloc-cheese {
  background-color: rgba($primary, 0.10);
  margin-top: 100px;
  padding: 80px 40px 80px 230px;
  position: relative;
}

.col-50 {
  width: 50%;
  position: relative;
  z-index: 3;
  display: inline-block;
}

.cheese {
  position: relative;
  margin: 200px 0;
}

.cheesy {
  position: absolute;
  right: 12.2%;
  top: 0;
  width: 50%;
  z-index: 1;
}

.page-template-a-propos section h2 {
  margin-bottom: 80px;
}

.icon {
  right: 0;
  position: absolute;
  bottom: -18%;
}

.cidres {
  margin: 150px 12.2%;
  padding: 80px 28% 80px 80px;
  max-width: 1183px;
  background-color: rgba($primary, 0.10);
  position: relative;
}

.cidre-img {
  position: absolute;
  right: -200px;
  top: 4%;
}

.citation {
  padding: 100px 15% 100px 12.2%;
}

.compotes {
  padding: 100px 12.2% 100px 0;
}

.pic-compote {
  width: 100%;
  height: 584px;
}

.pax {
  background-color: rgba($primary, 0.10);
  margin: 100px 0 100px 12.2%;
  padding: 80px 12.2% 80px 80px;
}

.other {
  background-color: $secondary;
  padding: 200px 12.2% 100px 0;
  position: relative;
}

.flex-grid {
  display: flex;
  align-content: center;
}

.flex-grid-second {
  display: flex;
  justify-content: space-between;
}

.flex-grid .flexmosaic {
  flex: 1;
}

.mosaic {
  display: inline-block;
  width: 50%;
}

.vinaigrettes {
  height: 619px;
  width: 24%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  position: relative;
  z-index: 2;
}

.carre,
.carre2 {
  height: 338px;
  width: 50%;
  flex: 0 0 49%;
  margin-bottom: 10px;
  text-align: center;
}

.carre {
  margin-right: 5px;
}

.carre2,
.carre {
  margin-left: 5px;
}

.horizontale {
  width: 50%;
  height: 272px;
  flex: 0 0 50%;
  margin-left: 5px;
  text-align: center;
}

.bouclier {
  position: absolute;
  top: 130px;
  left: 7%;
  z-index: 0;
}

.other-title {
  width: 25%;
  display: inline-block;
  position: relative;
  z-index: 3;
  top: -60px;
}

.other-title p {
  width: 56%;
  display: inline-block;
}

.compote-container {
  padding: 40px 150px;
}

.gray-line {
  height: 2px;
  width: 25%;
  background-color: white;
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
}

.other .container-button {
  position: relative;
  top: 20%;
}

.vinaigrettes .container-button {
  top: 40%;
}

.other .button {
  border: 2px solid white;
  color: white;
}

.back-button {
  background-color: rgba($primary, .80);
  width: 239px;
  margin: 0 auto;
  height: 94px;
  padding-top: 30px;
}

.back-button .button-yellow {
  color: white;
  border: 2px solid white;
}

.bloc-cheese-about {
  padding: 5% 24% 5% 10%;
}

/*Corpo*/

.container80 {
  position: relative;
}

.container80 img {
  position: absolute;
}

.container80 p {
  max-width: 652px;
  min-height: 115px;
}

.texte_gris {
  position: relative;
  top: 40px;
  font-size: 18px;
  line-height: 35px;
  color: #747474;
}

.prix_corpo {
  color: $primary;
  font-size: 25px;
  font-family: 'Inknut Antiqua', serif;
  font-weight: normal;
  clear: both;
  margin-bottom: 30px;
}

.container80 ul {
  list-style: none;
  margin-top: 60px;
  padding-left: 25px;
}

ul li.prix_corpo::before {
  content: "\2022";
  color: $primary;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.yellow-bloc {
  background-color: $primary;
  text-align: center;
  padding: 100px 40px;
}

.yellow-bloc p {
  color: white;
  font-size: 30px;
  line-height: 50px;
  max-width: 1016px;
  margin: 0 auto;
  display: block;
}

.corpo_pax {
  padding: 100px 12%;
}

.corpo_pax img {
  float: right;
}

.corpo_pax p {
  line-height: 35px;
}

.entreprise {
  margin: 0 8%;
}

.entreprise i {
  text-align: center;
  margin: 0 auto;
  display: block;
  max-width: 1088px;
  color: $primary;
  font-size: 40px;
  line-height: 50px;
}

.box-flex {
  display: flex;
  margin-top: 100px;
}

.boxy {
  background-color: rgba($primary, 0.1);
  flex-grow: 1;
  margin: 0 20px;
  width: 33.33%;
  max-width: 500px;
  padding: 80px 40px;
  text-align: center;
  min-height: 650px;
}

.boxy h3 {
  min-height: 120px;
  font-size: 40px;
}

.boxy p {
  font-size: 30px;
  color: $primary;
  line-height: 40px;
  font-weight: 300;
  margin-bottom: 0;
}

.avantages_corpo {
  margin: 100px 0;
}

.gray-back {
  background-color: #F5F5F5;
  height: 385px;
  width: 667px;
  text-align: right;
  padding: 80px;
}

.list-avantages {
  margin-top: 100px;
  padding: 0;
}

.list-avantages li {
  list-style: none;
  color: #747474;
  text-transform: uppercase;
  font-size: 30px;
  max-width: 665px;
  margin-bottom: 40px;
}

.commander {
  background-color: rgba($primary, 0.1);
  padding: 100px 12%;
}

.back-white {
  background-color: white;
  min-height: 583px;
  margin-top: 45px;
  padding: 80px;
}

.back-white h2 {
  margin-bottom: 30px;
  margin-top: 0;
}

.back-white > p {
  color: $graypoint;
  line-height: 35px;
  font-size: 16px;
  margin-bottom: 30px;
}

.pic-commander {
  height: 692px;
  width: 100%;
}

.aligner {
  text-align: center;
  max-width: 220px;
  margin: 0 auto;
  display: block;
  font-size: 22px;
  color: #747474;
  line-height: 40px;
}

.number-commande {
  font-size: 80px;
  color: #e3e3e5;
  border: 5px solid #e3e3e5;
  border-radius: 50%;
  padding: 0px;
  padding-left: 23px;
  padding-top: 0px;
  height: 102px;
  width: 102px;
  margin: 0 auto 40px;
}

.mobil {
  display: none;
}

.aligner a {
  color: #747474;
}

.no-touchevents .aligner a:hover {
  color: $primary;
}

.etape_img_container {
  border: 5px solid #e3e3e5;
  border-radius: 50%;
  height: 102px;
  width: 102px;
  margin: 0 auto;
  display: block;
  padding: 22px;
}

.rabais_corpo {
  max-width: 1200px;
  color: white;
  margin: 40px auto 0;
  display: block;
}

@media screen and (max-width: 1700px) {
  .texte_gris {
    top: 30px;
  }
}

@media screen and (max-width: 1600px) {
  .padding-content,
  .padding-blog {
    padding: 0 10%;
  }
  .author {
    padding: 40px 10%;
  }
  .cheesy {
    right: 10%;
  }
  .cidres {
    margin: 150px 10%;
    padding: 80px 35% 80px 80px;
  }
  .more-post, .nav-pagelink {
    padding: 25px 10% 55px;
  }
  .h2blog {
    margin-left: 10%;
  }

  .gray-back {
    width: 90%;
  }
  .boxy h3 {
    font-size: 32px;
  }
  .boxy p {
    font-size: 19px;
    line-height: 30px;
  }
  .other {
    padding: 200px 0 100px 0;
  }
  .icon {
    max-height: 300px;
  }
  .yellow-bloc p,
  .entreprise i {
    font-size: 25px;
    line-height: 40px;
  }
  .boxy {
    min-height: auto;
  }
  .list-avantages li {
    font-size: 21px;
  }
  .list-avantages li svg {
    position: relative;
    top: -7px;
    max-width: 40px;
  }
  .aligner {
    font-size: 16px;
    line-height: 31px;
  }
}

@media screen and (max-width: 1400px) {
  .cidres {
    padding: 80px 40% 80px 80px;
  }
}

@media screen and (max-width: 1300px) {

  .form-container {
    max-width: 100%;
    margin: 0 20px;
  }
  .padding-content,
  .padding-blog {
    padding: 0 5%;
  }
  .author {
    padding: 40px 5%;
  }
  .cheesy {
    right: 5%;
  }
  .h2blog {
    margin-left: 5%;
  }
  .more-post, .nav-pagelink {
    padding: 25px 5% 55px;
  }
  .cidres {
    margin: 150px 5% 0;
    padding: 80px 50% 80px 80px;
  }
  .option_2 svg, .option_3 svg {
    position: relative;
  }
  .vinaigrettes,
  .mosaic {
    width: 100%;
  }
  .other {
    padding: 200px 0 100px 0;
  }
  .text-patrimoine {
    padding: 60px 20px;
  }
  .carre, .carre2, .horizontale {
    margin-left: 0;
    margin-bottom: 0;
  }
  .carre {
    margin-right: 0;
  }
  .carre,
  .carre2 {
    flex: 0 0 50%;
  }
  .bloc-cheese {
    padding: 80px 40px 0 90px;
  }
  .compote-container {
    padding: 40px 60px;
  }
  .vinaigrettes {
    background-size: contain !important;
  }
  .boxy h3 {
    font-size: 32px;
  }
}

@media screen and (max-width: 1200px) {
  .container80 img {
    position: relative;
    margin-top: 30px;
  }
  .container80 p {
    max-width: 100%;
    min-height: auto;
  }
  .box-flex {
    display: block;
  }
  .boxy {
    margin: 0 auto 40px;
    display: block;
  }
  .gray-back {
    height: auto;
    width: 400px;
  }
  .pic-commander {
    height: 580px;
  }
  .list-avantages {
    padding: 0 40px;
    margin-top: 50px;
  }
  .entreprise i {
    font-size: 32px;
    line-height: 45px;
  }
  .mobil {
    display: block;
  }
  .desk {
    display: none;
  }
  .boxy {
    width: 100%;
  }
  .back-white {
    margin-top: 0;
  }
}

@media screen and (max-width: 1100px) {
  .boutique {
    padding: 80px 40px 40px;
    text-align: center;
  }
  .cidres {
    padding: 80px 58% 80px 80px;
  }
}

@media screen and (max-width: 1000px) {
  .padding-content,
  .padding-blog {
    padding: 0 20px;
  }
  .author {
    padding: 40px 20px;
  }
  .more-post, .nav-pagelink {
    padding: 25px 20px 55px;
  }
  .cheesy {
    right: 20px;
  }
  .h2blog {
    margin-left: 20px;
  }
  .cidres {
    margin: 150px 20px 0;
  }
  .boutique h2 {
    text-align: left;
  }

}

@media screen and (max-width: 979px) {
  .pad-text, .pad-text-right {
    padding: 40px 50px;
  }

  .membre,
  .blogue {
    margin-bottom: 20px;
  }
  .flex-3,
  .flex-4 {
    flex: 0 0 50%;
  }
  .faq-container {
    padding: 20px 6% 60px;
    position: relative;
  }
  .align-vertical {
    display: block;
  }
  .mobile-appear {
    display: block;
  }
  .mobile-display-none {
    display: none;
  }
  .back-appel,
  .back-splash-service {
    background-attachment: scroll !important;
  }
  .main-holder {
    padding: 0 40px;
  }
  .flexible {
    margin: 50px 0 0;
  }
  .col-mid img {
    margin: 0 auto;
    display: block;
  }
  .explorer {
    width: 200px;
    padding: 36px 0 0 0px;
  }
  .back-principal {
    max-width: initial;
  }
  .first-slide {
    width: 100% !important;
  }
  .campagne {
    min-height: auto;
  }
  .store {
    padding: 60px 0;
    text-align: center;
  }

  .cheesy {
    position: relative;
    right: 0;
    width: 100%;
  }
  .col-50 {
    width: 100%;
  }
  .bloc-cheese {
    margin-top: 0px;
    padding: 80px 40px 0 40px;
  }
  .cidre-img {
    position: relative;
    right: initial;
  }
  .cidres {
    padding: 80px 40px;
    max-width: 100%;
  }
  .container-img {
    padding: 0;
  }
  .boutique .first-slide {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .boutique .woocommerce ul.products[class*=columns-] li.product:nth-child(2n), .boutique .woocommerce-page ul.products[class*=columns-] li.product:nth-child(2n),
  .boutique .woocommerce ul.products[class*=columns-] li.product, .boutique .woocommerce-page ul.products[class*=columns-] li.product {
    float: none !important;
  }
  .boutique .woocommerce ul.products li.product {
    width: 100% !important;
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 720px) {
  .flex-container {
    display: block;
  }
  .flex-3, .flex-4 {
    flex: none;
  }
}

@media screen and (max-width: 640px) {
  .padding {
    padding: 80px 9%;
  }
  .appel {
    padding: 60px 0;
  }
  .membre-container,
  .realisation-container,
  .contact,
  .service-container,
  .padding {
    padding: 60px 20px;
  }
  .col-6 {
    display: block;
    width: 100%;
  }
  .flex-3, .flex-4 {
    flex: none;
  }
  .social-share a {
    height: 35px;
    width: 35px;
  }
  input, select {
    height: 40px;
  }
  #gform_submit_button_1 {
    height: auto;
  }
  .flexible {
    margin: 0;
  }
  .pad-text, .pad-text-right {
    padding: 40px 20px;
  }
  .flex-3 .blogue {
    margin: 0 5px 20px;
  }
  .fournisseurs img {
    max-height: 70px;
  }
  .link-filter {
    padding-left: 0;
  }
  .title-filter {
    padding: 0 20px 50px;
  }
  .filter-blogue ul li {
    margin: 13px 0;
  }
  .max-width-text {
    max-width: 90%;
  }
  .pad-text h2,
  .pad-text-right h2 {
    margin-bottom: 40px;
  }
  .boutique img {
    max-width: 250px !important;
    top: 125px;
  }
  .principal .mask {
    max-width: initial;
    right: 0px;
  }
  .explorer {
    width: 200px;
    padding: 19px 0 0;
    height: 87px;
    top: 25px;
  }
  .best-text {
    position: absolute;
    right: 0px;
    left: 0;
    bottom: 0;
    min-width: 120px;
    height: 25%;
    width: 100%;
  }
  .best-text p {
    margin-top: 10px;
  }
  .white-line {
    display: none;
  }
  .boutique img.logo-product {
    margin: 0 !important;
  }
  .hide-sm {
    display: none;
  }
  .share-single {
    top: -70px;
  }
  .boutique h2 {
    margin-left: 20px;
    margin-bottom: 60px;
  }
  .option_2 svg, .option_3 svg {
    height: 75px;
  }
  .option_2 {
    padding: 40px 0 0;
  }
  .option_3 {
    padding: 40px 0;
  }
  .cueillette img {
    max-height: 140px;
  }
  .photo-financement {
    width: 100%;
    height: 450px;
  }
  .financement {
    padding: 60px 0;
  }
  .container-img {
    padding-right: 0;
  }
  .caseus {
    left: 0;
    max-height: 90px;
  }
  .bloc-cheese {
    padding: 40px 20px;
  }
  .icon {
    right: -100px;
    bottom: -35%;
  }
  .cidres {
    padding: 40px 20px 0;
    margin: 40px 20px 0;
  }
  .page-template-a-propos section h2 {
    margin-bottom: 40px;
  }
  .citation {
    padding: 60px 20px 0;
  }
  .compotes {
    padding: 100px 0 0;
  }
  .pic-compote {
    height: 285px;
  }
  .compote-container {
    padding: 40px 20px;
  }
  .button {
    padding: 15px 35px;
  }
  .pax {
    margin: 0;
    padding: 40px 20px;
  }
  .other {
    padding: 100px 0 50px;
  }
  .vinaigrettes {
    height: 410px;
  }
  .carre, .carre2 {
    width: 100%;
  }
  .flex-grid, .flex-grid-second {
    display: block;
  }

  .horizontale {
    width: 100%;
  }
  .bouclier {
    top: 35px;
    left: initial;
    right: -85px;
    max-height: 200px;
  }
  .other-title p {
    width: 100%;
    margin-left: 40px;
  }
  .bloc-cheese-about {
    padding: 40px 20px;
  }
  .back-principal {
    right: initial;
  }
  .text-formation {
    padding: 40px 5%;
  }
  .back-white {
    margin-top: 0;
    padding: 40px 20px;
    height: auto;
  }
  .back-white h2 {
    margin-bottom: 30px;
    text-align: center;
  }
  .texte_gris {
    top: 0;
  }
  .yellow-bloc {
    padding: 60px 5%;
  }
  .yellow-bloc p {
    font-size: 25px;
    line-height: 35px;
  }
  .corpo_pax {
    padding: 60px 5%;
  }
  .entreprise i {
    font-size: 25px;
    line-height: 35px;
  }
  .boxy h3 {
    min-height: auto;
    font-size: 25px !important;
  }
  .boxy p {
    font-size: 24px;
    line-height: 33px;
  }
  .boxy {
    min-height: auto;
    padding: 40px;
  }
  .gray-back {
    width: 100%;
    text-align: center;
    padding: 60px 0;
  }
  .list-avantages li {
    font-size: 24px;
  }
  .list-avantages li svg {
    position: relative;
    top: -5px;
  }
  .corpo_pax img {
    float: none;
    max-height: 150px;
    margin: 0 auto;
    display: block;
  }
  .container80 img {
    max-height: 150px;
    margin: 0 auto;
    display: block;
  }
  .pic-commander {
    height: 305px;
  }
  .number-commande {
    margin: 0 auto 20px;
  }
  .aligner {
    margin-bottom: 40px;
    line-height: 30px;
    font-size: 19px;
  }
  .list-avantages {
    padding: 0 5%;
  }
  .avantages_corpo {
    margin: 50px 0;
  }
  .box-flex {
    margin-top: 50px;
  }
  .number-commande {
    font-size: 60px;
    padding-left: 18px;
    height: 80px;
    width: 80px;
  }
  .back-white > p {
    line-height: 25px;
    font-size: 13px;
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
  .home .boutique.padding {
    padding: 60px 20px 0 0;
  }
  .list-avantages li {
    font-size: 21px;
  }
  .boxy p {
    font-size: 20px;
    line-height: 30px;
  }
  .entreprise i {
    font-size: 21px;
    line-height: 29px;
  }
  .corpo_pax p {
    line-height: 25px;
  }
  .yellow-bloc p {
    font-size: 21px;
    line-height: 31px;
  }
  .texte_gris {
    line-height: 28px;
  }
  .cadeau_corpo i {
    line-height: 26px;
  }
}

.thumbnail {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
