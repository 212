footer{
	width: 100%;
	background-color: $secondary;
	padding: 50px 10% 140px;
	position: relative;
}

.copyright{
	position: absolute;
	bottom: 10px;
	left: 10%;
	font-size: 13px;
	color: $graydark;
}

.ngv{
	color: white;
	font-weight: bold;
	font-size: 14px;
}

.madeby{
	position: absolute;
	color: $graydark;
	bottom: 40px;
	right: 0px;
	left: 0;
    text-align: center;
	font-size: 12px;
}

.propulseby span.ngv{
	font-size: 12px;
	color: $graydark;
}

.propulseby{
	position: absolute;
	color: white;
	bottom: 20px;
	right: 0px;
	left: 0;
    text-align: center;
	font-size: 14px;
	font-weight:bold;
}


.btn_retour img{
	transform: rotate( 180deg );
}

.btn_retour{
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 50px;
	top: 58px;
	cursor: pointer;
	transition: 1s;
}

.no-touchevents .btn_retour:hover{
	transform: scale(1.1);
}

.menu-footer{
	display: flex;
	align-items: center;
	justify-content: center;
	width:100%;
	margin-bottom: 40px;
}

.menu-footer .menu-item {
	flex-grow: 1;
	list-style: none;
	text-align:center;
}

.menu-footer .menu-item:first-child,
.menu-footer .menu-item:nth-child(2),
.menu-footer .menu-item:nth-child(3){
	text-align: left;
}

.menu-footer .menu-item a{
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	color: #7F7F7F;
}

.no-touchevents .menu-footer .menu-item a:hover{
	color: $primary;
}
.logo-footer{
	flex-grow: 2;
	background: url('/wp-content/themes/NGV/assets/src/img/logo-footer.svg')no-repeat 50% 50%;
	background-size:contain;
	height:150px;
}

.logo-footer a{
	color: $secondary!important;
}

.no-touchevents footer a:hover{
	color: $primary;
}
footer h3,
footer .menu-item-has-children > a{
	text-transform: uppercase;
	color: $graydark;
	font-size: 14px;
	font-weight: bold;
	margin-top: 0;
	font-family: 'Roboto', sans-serif;
}

footer p{
	font-size: 14px;
	color: white;
	line-height: 26px;
}

.social p{
	color: #808080;
	font-weight: bold;
	font-size: 12px;
}

.flex,
.menu-footer2{
	display: flex;
	justify-content: center;
	width:100%;
}

.menu-footer2 .menu-item,
.flexgrow{
	flex-grow: 1;
}

.flexgrow{
	margin-left: 20%;
}
footer .menu-item-has-children{
	list-style: none;
}

footer .menu-item-has-children:after{
	content: "";
}

footer .sub-menu{
	display:block!important;
	background-color: transparent;
}

footer .sub-menu li{
	list-style: none;
}

footer .menu-item-has-children > a{
	pointer-events: none;
}


footer .sub-menu li a{
	color: white;
	font-size: 14px;
}

.menu-footer,
.menu-footer2{
	padding: 0;
}

.circle-social svg path,
.circle-social svg path g{
  fill: $whity!important;
  transition: 1s;
}


.circle-social svg{
	fill: $whity!important;
	height: 20px;
	width: 20px;
	transition: 1s;
	margin-right: 20px;
    position: relative;
    top: 4px;
}

.circle-social p{
	transition: 1s;
}

.no-touchevents .circle-social:hover > svg,
.no-touchevents .circle-social:hover > svg path,
.no-touchevents .circle-social:hover > svg path g,
.no-touchevents .circle-social:hover > p{
	fill: $primary!important;
	color: $primary;
}
.circle-social{
  padding: 10px;
  border-radius: 50%;
  height: 40px;
  width: 150px;
  transition: 1s;
}

.circle-social svg,
.circle-social p{
	display: inline-block;
}

.social{
	width: 42%;
	position: absolute;
	bottom: 30px;
	right: 0;
}

.social a{
	display: inline-block;
	margin: 0 15px;
}

.ngv{
	height: 100px!important;
	margin: 0 auto;
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	text-align:center;
	bottom: 0px;
}
.menu-footer2 .sub-menu li.menu-item:nth-child(2){
	margin-top: initial;
}

@media screen and (max-width: 1600px) {
	footer{
		padding: 30px 10% 80px;
	}
	.copyright{
		left: 10%;
	}
	.madeby,
	.propulseby {
	    right: 30px;
	}
	.flexgrow{
		margin-left: 2%;
	}
}	

@media screen and (max-width: 1300px) {
	footer{
		padding: 30px 5% 80px;
	}
	.copyright{
		left: 5%;
	}
}	

@media screen and (max-width: 1200px) {
	.flexgrow {
	    margin-left: 0;
	}
	.flexgrow{
		width:50%;
	}
	.flexgrow,.flexgrow div, .menu-footer2 .menu-item{
		text-align:center!important;
	}
	.ngv{
		left: 0px;
	}
	.menu-footer2 .menu-item{
		width: 50%;
    	margin: 0 auto;
    	text-align: center;
	}
}

@media screen and (max-width: 1000px) {
	footer{
		padding: 30px 20px 75px;
	}
	.copyright{
		left: 20px;
	}
}	

@media screen and (max-width: 979px) {
	.social a {
	    margin: 0px;
	}
	.circle-social svg {
	    margin-right: 0px;
	}
	.circle-social {
	    width: 124px;
	}
}	

@media screen and (max-width: 879px) {
	.btn_retour{
		top: 20px;
		width: 35px;
	}
	.copyright {
	    margin-top: 52px;
	    text-align: center;
	}
	footer {
	}
	.madeby {
	    top: 130px;
	    right: 0;
	    left:0;
	    text-align: center;
	}
	.propulseby{
		top: 153px;
		right: 0;
	    left:0;
	    text-align: center;
	}
	.menu-footer li{
		display: none;
	}
	.menu-footer .logo-footer{
		display: block;
	}
	.menu-footer{
		margin-bottom: 20px;
	}
	.propulseby,
	.madeby{
		top: inherit;
	}
	.copyright{
		position: relative;
		text-align: center;
		left: 0;
		margin-bottom: 30px;
	}
	.social{
		width:100%;
		position: relative;
	}
	.flexgrow, .menu-footer2 .menu-item{
		width: 100%;
	}
	.social {
    right: 0px;
	}
	.logo-footer{
		height:115px;
	}
}		

@media screen and (max-width: 450px) {
	.flexgrow, .menu-footer2 .menu-item{
		flex-grow: initial;
	}
	.flex, .menu-footer2{
		display: block;
	}
	.menu-footer2 .menu-item{
		margin-bottom: 30px;
	}
	.menu-footer2 .sub-menu li{
		margin-bottom: 5px;
	}
}	