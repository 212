/*
	Utilities
		Layout
 */
.u-clear {
	clear: both !important;
	width: 100% !important;
}
.u-pull-right {
	float: right !important;
}
.u-pull-left {
	float: left !important;
}
.u-offset-parent {
	position: relative;
}
.u-overflow-visible {
	overflow: visible !important;
}
/* Visible / Hidden */
.u-hidden {
	display: none !important;
}
.u-visible {
	display: block !important;
}
@each $bp in $breakpoints {
	@if map-get($bp, max-width) == null {
		.u-hidden-#{map-get($bp, name)} {
			display: none !important;
		}
		.u-visible-#{map-get($bp, name)} {
			display: block !important;
		}
	} @else {
		@media screen and (max-width: #{map-get($bp, max-width)}) {
			.u-hidden-#{map-get($bp, name)} {
				display: none !important;
			}
			.u-visible-#{map-get($bp, name)} {
				display: block !important;
			}
		}
	}
}