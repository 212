$xs: 575.98px;
$sm: 767.98px;
$md: 991.98px;
$lg: 1199.98px;
$xl: 1449.98px;
$xxl: 1919.98px;


.v-fromages {

  section {
    overflow-x: hidden;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .button-yello {
    display: inline-block;
  }

  &__hero {
    position: relative;
    background-color: $blackAlt;
    padding-bottom: 150px;

    .parallax--cheese {
      position: absolute;
      left: calc(50% - 350px);
      top: 25%;
      display: block;
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
      z-index: 6;
      pointer-events: none;

      @media(max-width: $md) {
        top: 150px;
      }

      @media(max-width: $sm) {
        top: 250px;
        max-width: 400px;
        left: calc(50% - 200px);
      }

    }

    &--title {
      position: relative;
      display: block;
      padding: 140px 0 140px 0;

      text-align: center;

      h1 {
        margin: 0 0 200px 0;
        font-size: 50px;

        @media(max-width: $md) {
          font-size: 40px;
        }

        @media(max-width: $sm) {
          font-size: 30px;
        }
      }
    }

    &--content {
      position: relative;
      display: block;
      overflow: hidden;

      .small--container {
        position: relative;
        display: block;
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 300px 30px 0;
        z-index: 5;

        @media(max-width: $sm) {
          padding: 150px 30px 0;
        }

        .logos {
          display: block;
          margin: 80px auto 0 auto;
        }
      }

      .content {
        display: block;
        text-align: center;
        margin-bottom: 160px;

        p {
          color: white !important;
          line-height: 1.8;
        }
      }

      .sansLactose {
        display: block;
        font-size: 40px;
        font-style: italic;
        text-align: center;

        p {
          color: $primary !important;
          line-height: 1.8;
        }
      }

      .background {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;

        z-index: 2;

        .gradiant {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: linear-gradient(180deg, rgba($blackAlt, 0) 0%, rgba($blackAlt, 1) 90%);
          z-index: 3;
        }

        img {
          position: relative;
          width: 100%;
          z-index: 2;
        }
      }
    }
  }

  &__liste {
    position: relative;
    display: block;
    padding-bottom: 180px;

    @media(max-width: $sm) {
      padding-bottom: 0;
    }

    &--cat {
      display: block;
      padding-top: 180px;

      @media(max-width: $sm) {
        padding-top: 130px;
      }

      .slick-track {
        display: flex !important;
        pointer-events: none;
      }

      h2.cat-title {
        display: block;
        font-size: 114px;
        color: #E3E3E5;
        margin: 0 0 14px 0;

        @media (max-width: $md) {
          margin-bottom: 25px;
          font-size: 60px;
        }

        @media (max-width: $sm) {
          font-size: 50px;
          margin-bottom: 30px;
        }

        .text {
          text-wrap: nowrap;

          &:after {
            content: '•';
            padding: 0 20px;
          }
        }
      }

      &.o-firstItem {
        position: relative;
        padding: 180px 0;
        background-color: $blackAlt;

        @media(max-width: $sm) {
          padding: 90px 0 15px 0;
        }

        h2.cat-title {
          color: #484240;
        }

        .feuille_parallax {
          position: absolute;
          right: -400px;
          top: 50%;
          display: block;
          width: 100%;
          max-width: 800px;
          transform: translateY(-50%);
          opacity: .15;
        }
      }

      .feuille_parallax {
        position: absolute;
        right: -400px;
        top: 50%;
        display: block;
        width: 100%;
        max-width: 800px;
        opacity: .15;
      }

    }
  }

  &__boutique {
    position: relative;
    display: block;
    padding: 250px 0 150px 0;

    @media(max-width: $sm) {
      padding: 0px 0 75px 0;
    }

    .feuille_parallax {
      position: absolute;
      top: -100px;
      left: 0;
      opacity: .2;
      z-index: -1;
    }

    &--heures {
      display: block;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto 60px auto;

      text-align: center;

      .image {
        position: relative;
        display: block;
        padding-bottom: 60%;
      }

      h2 {
        font-size: 60px;
        margin: 0 0 100px 0;

        @media(max-width: $md) {
          font-size: 30px;
        }
      }

      .box {
        position: relative;
        display: block;
        padding: 60px;

        font-size: 18px;

        color: #747474;
        background-color: #F5F5F5;

        @media(max-width: $md) {
          padding: 60px 30px;
        }

        .title {
          display: block;
          margin-bottom: 30px;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2px;

          text-transform: uppercase;
          color: $primary;
        }

        .heures--small {
          display: block;
          margin-top: 40px;
          font-size: 14px;
        }

        .parallax {
          position: absolute;
          bottom: -150px;
          right: -150px;

          display: block;
          width: 100%;
          max-width: 300px;

          @media(max-width: $md) {
            max-width: 200px;
            bottom: -100px;
            right: -100px;
          }
        }
      }
    }

    &--adresse {
      display: block;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      text-align: center;

      .title {
        display: block;
        margin-bottom: 50px;
        font-size: 30px;
        color: #747474;
      }

      address {
        display: block;
        margin-bottom: 50px;
        text-align: center;
        font-style: normal;
        font-size: 18px;
      }
    }

    .button-yellow {
      display: inline-block;
    }
  }

  &__degustation {
    position: relative;
    display: block;
    padding: 150px 0;
    background-color: rgba(#EFB55A, .1);

    @media(max-width: $sm) {
      padding: 75px 0;
    }

    &--row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      @media(max-width: $lg) {
        align-items: stretch;
      }

      @media(max-width: $sm) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }
    }

    &--content,
    &--image {
      position: relative;
      display: block;
      width: 100%;
      max-width: 50%;

      @media(max-width: $sm) {
        max-width: 100%;
      }
    }

    &--content {
      display: block;

      .box {
        display: block;
        padding: 80px 60px;
        margin-bottom: 60px;

        font-size: 25px;
        line-height: 1.8;
        color: #747474;

        background-color: white;

        h2 {
          margin-top: 0;
        }

        p:last-child {
          margin-bottom: 0;
        }

        @media(max-width: $lg) {
          font-size: 18px;
          padding: 60px;
          margin-bottom: 30px;
          height: calc(100% - 90px);
        }
      }

      .box-btn {
        display: block;
        padding-left: 60px;

        .button-yellow {
          display: inline-block;
        }
      }
    }

    &--image {
      position: relative;

      .image {
        position: relative;
        display: block;
        padding-top: 106%;
      }
    }
  }
}


//Présentation du fromage
.c-fromage {
  position: relative;
  display: block;

  &.fromage--droit {
    .c-fromage__row {
      flex-direction: row-reverse;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: -60px;
    align-items: center;
    padding: 24px 0;
    @media(max-width: $sm) {
      margin-bottom: 6rem
    }
  }

  &__image,
  &__content {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 50%;
    padding: 0 60px;

    .o-darkMode p {
      color: white;
    }

    @media(max-width: $sm) {
      max-width: 100%
    }
  }

  &__content {

    line-height: 1.8rem;

    &--description {
      display: block;
      margin-bottom: 50px;
      font-size: 18px;
      color: #747474;

      p:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      display: block;
      padding: 0;
      margin: 0 0 50px 0;
      list-style: none;

      li {
        &.title {
          display: block;
          margin-bottom: 15px;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 2px;

          text-transform: uppercase;
          color: #B3B3B3;
        }

        color: $primary;
      }
    }
  }
}
